import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import websiteRanking from "../../../../src/assets/images/website-ranking.webp";
import FirstRank from "../../../../src/assets/images/first-rank-in-google.webp";
import seoservice from "../../../../src/assets/images/whitehat-seo-process.webp";
import benefits from "../../../../src/assets/images/benefits-for-google-first-rank.webp";
import websiterank1 from "../../../../src/assets/images/website-rank-no1.webp";
import seoscore1 from "../../../../src/assets/images/seoscore1.png";
import seoscore2 from "../../../../src/assets/images/seoscore2.png";
import seoscore3 from "../../../../src/assets/images/seoscore3.png";

export default function About() {
  const [about, setAbout] = useState([]);
  const [testimonial, setTestimonials] = useState([]);
  const expertData = [
    { title: "Best SEO Expert in India", link: "/details/seo-expert" },
    { title: "Google Ranking Expert", link: "/details/google-ranking-expert" },
    { title: "GMB Ranking Specialist", link: "/details/gmb-specialist" },
    { title: "Internet Influencer", link: "/details/internet-influencer" },
    { title: "Google Influencer", link: "/details/google-influencer" },
    { title: "Best Local SEO Expert", link: "/details/local-seo-expert" },
    {
      title: "Google Ranking Specialist",
      link: "/details/google-ranking-specialist",
    },
    { title: "Google SEO Expert", link: "/details/google-seo-expert" },
  ];

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
    // Uncomment if you want to fetch dynamic content
    // getAboutContent();
  }, []);

  // Example function to get content (Uncomment if needed)
  // const getAboutContent = () => {
  //   api.get("/getAboutUs", { recordType: "Record" }).then((res) => {
  //     setAbout(res.data.data);
  //     api.post("/getContent", { recordType: "Testimonialpart" }).then((res) => {
  //       setTestimonials(res.data.data);
  //     });
  //   });
  // };

  return (
    <>
      <Helmet>
        <title>About Us</title>
      </Helmet>

      <section
        className="page-title page-title-overlay bg-cover overflow-hidden"
        style={{ backgroundImage: "url('/images/background/about.jpg')" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h1 className="text-white position-relative">
                Website Ranking Services
                <span className="watermark-sm">Website Ranking Services</span>
              </h1>
              <p className="text-white pt-4 pb-4">Zammy Saif</p>
            </div>
            <div className="col-lg-3 ml-auto align-self-end">
              <nav
                className="position-relative zindex-1"
                aria-label="breadcrumb"
              ></nav>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container-fluid">
          <div class="row medium-padding120 bg-border-color">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 md-mb-30 text-m-center">
                  <img
                    loading="lazy"
                    src={websiteRanking}
                    alt="Website Ranking Services"
                    title="Boost Your Website Ranking with our SEO Services"
                  />
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-m-center">
                  <div class="heading">
                    <h2 class="h2 heading-title mb-0">
                      Boost your Website Ranking
                    </h2>
                    <div class="heading-line">
                      <span class="short-line"></span>
                      <span class="long-line"></span>
                    </div>
                  </div>
                  <p class="text-l-justify mb-20">
                    In today's competition, having a strong online presence in
                    google search result is vital for any business. A website is
                    the first point of contact between a business and its
                    potential customers. A well-designed website with optimized
                    content and high search engine rankings can significantly
                    improve a business's visibility and attract more customers.
                    Our Google Ranking Experts Team offers website ranking
                    services that help our clients achieve the first rank on
                    first page of Google search results.
                  </p>
                  <a
                    href="contact-zammy-zaif.php"
                    class="btn btn-medium btn--secondary"
                  >
                    <span class="text">Get Quote</span>
                    <span class="semicircle"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container">
          <div class="row medium-padding120">
            <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 md-mb-30 text-m-center">
              <div class="heading">
                <span class="subhead">Our Goal for Each Project</span>
                <h2 class="h2 heading-title mb-0">
                  Achieving First Rank in Google
                </h2>
                <div class="heading-line mb-10">
                  <span class="short-line"></span>
                  <span class="long-line"></span>
                </div>
              </div>
              <p class="mb-10 text-l-justify">
                Our google SEO expert team is dedicated to achieving the first
                rank for your webpage or website. We believe that being on the
                first page of Google is not enough, and strive to attain the
                coveted first rank within 90 to 180 days.
              </p>
              <p class="mb-0 text-l-justify">
                Our team of SEO experts use proven strategies and techniques to
                improve your website's ranking. We analyze your website,
                identify the areas that need improvement, and develop a
                customized plan to optimize your website's content and
                structure. Our focus is not just on improving your website's
                visibility, but also on increasing your website's credibility
                and authority.
              </p>
            </div>

            <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 text-m-center">
              <img
                loading="lazy"
                src={FirstRank}
                alt="Achieving First Rank in Google"
                title="Get your Website Rank Higher on Google"
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-fluid">
          <div class="row">
            <div class="seo-score scrollme"   style={{width:'100%'}} >
              <div class="container">
                <div class="row">
                  <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-xs-12 col-sm-12">
                    <div class="seo-score-content align-center">
                      <div class="heading align-center">
                        <h3 class="h3 heading-title">
                          Looking for More Traffic & Leads?
                        </h3>
                        <p class="heading-text">
                          We have helped 38 top brands in the world and 250+
                          local brands for the last 14 years.
                        </p>
                      </div>

                      <div class="seo-score-form">
                        <form
                          class="input-inline"
                          method="post"
                          action="https://bamstechnologies.org/mailer/it2-leads.php"
                        >
                          <div class="row">
                            <div class="col-lg-5 no-padding col-md-12 col-xs-12 col-sm-12" >
                              <input
                                name="web"
                                class="input-dark site"
                                placeholder="Type in your Website URL*"
                                required
                              />
                            </div>
                            <div class="col-lg-3 no-padding col-md-12 col-xs-12 col-sm-12">
                              <input
                                name="phone"
                                class="input-dark phone"
                                placeholder="Your Phone*"
                                type="number"
                                required
                              />
                            </div>
                            <div class="col-lg-4 no-padding col-md-12 col-xs-12 col-sm-12" style={{marginTop:'10px'}}>
                              <input
                                name="email"
                                class="input-dark e-mail"
                                placeholder="Your Email*"
                                type="email"
                                required
                              />
                            </div>

                            <div class="col-lg-12 no-padding col-md-12 col-xs-12 col-sm-12">
                              <input
                                style={{
                                  borderRadius: "50px",
                                  paddingTop: "5px",
                                }}
                                name="text"
                                class="input-dark site"
                                placeholder="Subject"
                                required
                              />
                            </div>
                          </div>
                          <input type="hidden" name="page_url" value="" />
                          <div
                            class=""
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              data-sitekey="6LcsApkhAAAAAMYgkxg_Du9jRbCXlHuJbCanu8FU"
                              required
                              class="g-recaptcha"
                            ></div>
                          </div>

                          <button class="btn btn-medium btn--green btn-hover-shadow">
                            <span class="text">Enquiry Now!</span>
                            <span class="semicircle"></span>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="images">
                {/* <img loading="lazy" src={seoscore1} alt="image" style={{opacity:1, top:'-10px', paddingTop:'300px'}} /> */}
                 <img loading="lazy" src={seoscore2} alt="image" style={{opacity:1, top:'-10px', paddingTop:'540px'}} />
                <img loading="lazy" src={seoscore3} alt="image" style={{opacity:1, top:'-10px', paddingTop:'350px'}} /> 
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-fluid">
          <div class="row medium-padding120 bg-blue-color d-flex align-items-stretch">
            <div class="container">
              <div class="row d-flex align-items-stretch">
                <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 md-mb-30 text-m-center d-flex justify-content-center align-items-center">
                  <img
                    loading="lazy"
                    src={seoservice}
                    alt="White Hat SEO Process"
                    title="SEO strategies that function within the rules and expectations of search engines and searchers."
                  />
                </div>

                <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 text-m-center d-flex flex-column justify-content-between">
                  <div class="heading">
                    <h2 class="h2 heading-title c-white mb-0">
                      Organic White Hat SEO Services
                    </h2>
                    <div class="heading-line mb-10">
                      <span class="short-line bg-yellow-color"></span>
                      <span class="long-line bg-yellow-color"></span>
                    </div>
                  </div>
                  <p class="text-l-justify c-white mb-10">
                    In our company, we follow only organic White Hat SEO
                    processes as per Google's guidelines. We never resort to
                    third-party SEO tools or plugins, as Google has not
                    recommended any such tools till date. By following these
                    guidelines, we ensure that your website rank lasts longer as
                    compared to your competitors who may use Black or Grey Hat
                    SEO techniques.
                  </p>
                  <p class="text-l-justify c-white mb-0">
                    Our organic SEO services involve optimizing your website's
                    content with relevant keywords and phrases that are likely
                    to be searched by potential customers. We also focus on
                    improving your website's user experience, including page
                    load speed, mobile responsiveness, and overall design. Our
                    team also builds high-quality backlinks to your website,
                    which is an important factor in improving your website's
                    credibility and authority.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container-fluid">
          <div class="row medium-padding120 bg-border-color d-flex align-items-stretch">
            <div class="container">
              <div class="row d-flex align-items-stretch">
                <div class="col-lg-8 col-sm-12 col-xs-12 md-mb-30 d-flex flex-column justify-content-between">
                  <div class="heading">
                    <h2 class="h2 heading-title mb-0">
                      Benefits of Achieving Google's 1st Rank
                    </h2>
                    <div class="heading-line">
                      <span class="short-line"></span>
                      <span class="long-line"></span>
                    </div>
                  </div>
                  <p class="text-l-justify mb-10">
                    By achieving Google's 1st rank for your website, you will
                    reap considerable advantages. In comparison to second and
                    third positions, you can anticipate an impressive 80% surge
                    in leads and sales. This clearly demonstrates that our
                    services not only enhance your website's visibility but also
                    make a substantial contribution to the growth of your
                    business. Securing the first position on Google's search
                    results establishes a positive impression on potential
                    customers, fostering trust in your brand and prompting them
                    to choose your products or services over competitors.
                  </p>
                  <p class="text-l-justify mb-0">
                    A higher website ranking translates to increased organic
                    traffic, leading to more conversions and leads. Our website
                    ranking services are strategically designed to elevate your
                    brand awareness, significantly improve click-through rates,
                    secure more authentic and higher rankings, while also
                    reducing marketing costs and attracting potential
                    sales-qualified customers.
                  </p>
                </div>

                <div class="col-lg-4 col-sm-12 col-xs-12 text-m-center d-flex justify-content-center align-items-center">
                  <img
                    loading="lazy"
                    src={benefits}
                    alt="Benefits for Google's First Rank"
                    title="Benefits of Ranking #1 on Google for Your B2B and B2C Organization"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container-fluid">
          <div class="row medium-padding120 bg-orange-color">
            <div class="container">
              <div class="row">
                <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 md-mb-30 text-m-center">
                  <img
                    loading="lazy"
                    src={websiterank1}
                    alt="White Hat SEO Process"
                    title="Best Google SEO Ranking Services Providers in 2023"
                  />
                </div>

                <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 text-m-center">
                  <div class="heading">
                    <h2 class="h2 heading-title c-white mb-0">
                      Why Choose Zammy Zaif for Website Ranking Services?
                    </h2>
                    <div class="heading-line mb-10">
                      <span class="short-line bg-yellow-color"></span>
                      <span class="long-line bg-yellow-color"></span>
                    </div>
                  </div>
                  <p class="text-l-justify c-white mb-10">
                    Choosing the right website ranking service provider can be a
                    daunting task. Zammy Zaif and his team of experienced and
                    certified SEO experts who have helped numerous businesses
                    achieve their website ranking goals. Our team uses only
                    ethical and effective SEO techniques to improve your
                    website's ranking and we keep you updated on the progress
                    through regular reports.
                  </p>
                  <p class="text-l-justify c-white mb-0">
                    We also offer affordable website ranking services, which are
                    tailored to meet your specific requirements and budget. Our
                    goal is to help your business grow by improving your
                    website's visibility, credibility and authority. When you
                    choose Zammy Zaif for website ranking services, you can be
                    assured of quality services and tangible results.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container">
          <div class="row medium-padding120">
            <div class="row">
              <div class="col-lg-12 col-sm-12 col-xs-12 text-center">
                <div class="heading">
                  <span>ZAMMY ZAIF</span>
                  <h3 class="h3 heading-title mb-0">Contact Us Today</h3>
                  <div class="heading-line mb-10">
                    <span class="short-line"></span>
                    <span class="long-line"></span>
                  </div>
                </div>
                <div class="">
                  <p class="text-center mb-20">
                    If you want to improve your website's ranking and grow your
                    business, contact us today. Our team of SEO experts will be
                    happy to assist you and answer any queries you may have. We
                    look forward to helping
                  </p>
                  <a
                    href="contact-zammy-zaif.php"
                    class="btn btn-medium btn--dark"
                  >
                    <span class="text">Contact Now</span>
                    <span class="semicircle"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
