import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";
import gmbrankingexpert from "../../../../src/assets/images/gmb-ranking-expert.webp";
import GoogleBusiness from "../../../../src/assets/images/google-business-profile.webp";
import propertybuyer from "../../../../src/assets/images/property-buyers-in-london-result.webp";
import googlebusiness1 from "../../../../src/assets/images/google-business-ranking-performance.webp";
import shingle from "../../../../src/assets/images/shingle-roofing-experts-los-angeles-result.webp";
import progressiveac from "../../../../src/assets/images/progressive-ac-services-north-hollywood.png";
import realestate from "../../../../src/assets/images/realestate-coach.webp";
import dentru from "../../../../src/assets/images/dentru.webp";
import dentru1 from "../../../../src/assets/images/dentru-gurgaon.webp";
import roycleeves from "../../../../src/assets/images/roy-cleeves.webp";
import studio7rk from "../../../../src/assets/images/studio-7rk.webp";
import seoscore2 from "../../../../src/assets/images/seoscore2.png";
import seoscore3 from "../../../../src/assets/images/seoscore3.png";
import googleoptimization from "../../../../src/assets/images/google-business-profile-optimization.png";
import localsitation from "../../../../src/assets/images/local-citation-audit.webp";
import gmbseo from "../../../../src/assets/images/gmb-seo.webp";
import localseo from "../../../../src/assets/images/local-seo.webp";
import localbusiness from "../../../../src/assets/images/local-business.webp";
import richmedia from "../../../../src/assets/images/rich-media-citations.webp";
import seoreport from "../../../../src/assets/images/seo-report.webp";
import googlemybusiness from "../../../../src/assets/images/google-my-business-ranking-service.webp";
import localsitationbuilding from "../../../../src/assets/images/gmb-local-citation-building-service.webp";
import faq from "../../../../src/assets/images/faq.webp";

export default function About() {
  const options = {
    items: 5, // Number of items to display
    margin: 15, // Space between items
    loop: true, // Loop the carousel
    autoplay: true, // Enable autoplay
    autoplayTimeout: 3000, // Autoplay interval in milliseconds
    responsive: {
      0: { items: 1 }, // For smaller screens (phones)
      768: { items: 2 }, // For tablets
      992: { items: 3 }, // For desktops
      1200: { items: 5 }, // For larger desktops
    },
  };

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
    // Uncomment if you want to fetch dynamic content
    // getAboutContent();
  }, []);

  // Example function to get content (Uncomment if needed)
  // const getAboutContent = () => {
  //   api.get("/getAboutUs", { recordType: "Record" }).then((res) => {
  //     setAbout(res.data.data);
  //     api.post("/getContent", { recordType: "Testimonialpart" }).then((res) => {
  //       setTestimonials(res.data.data);
  //     });
  //   });
  // };

  return (
    <>
      <Helmet>
        <title>About Us</title>
      </Helmet>

      <section
        className="page-title page-title-overlay bg-cover overflow-hidden"
        style={{ backgroundImage: "url('/images/background/about.jpg')" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h1 className="text-white position-relative">
                GMB Ranking Services
                <span className="watermark-sm">GMB Ranking Services</span>
              </h1>
              <p className="text-white pt-4 pb-4">Zammy Saif</p>
            </div>
            <div className="col-lg-3 ml-auto align-self-end">
              <nav
                className="position-relative zindex-1"
                aria-label="breadcrumb"
              ></nav>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-fluid">
          <div class="row medium-padding120 d-flex align-items-stretch">
            <div class="container">
              <div class="row d-flex align-items-stretch">
                <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 md-mb-30 text-m-center d-flex flex-column justify-content-between">
                  <div class="heading">
                    <span>GMB Ranking Services</span>
                    <h2 class="h2 heading-title mb-0">
                      Business Profile Rank Optimization
                    </h2>
                    <div class="heading-line mb-10">
                      <span class="short-line"></span>
                      <span class="long-line"></span>
                    </div>
                  </div>
                  <p class="text-l-justify mb-10">
                    Google Business Profile - GMB Ranking Optimization is a
                    service offered by Zammy Zaif, a renowned Google Business
                    Profile expert. This service is designed to help businesses
                    improve their online visibility and increase their chances
                    of attracting potential customers locally. With years of
                    experience in the field, Zammy Zaif has developed an
                    extensive understanding of the Google Business Profile
                    platform and can leverage this knowledge to optimize a
                    business's profile to its fullest potential.
                  </p>
                  <p class="text-l-justify mb-0">
                    Through the Google Business Profile Ranking Optimization
                    service, Zammy Zaif assists businesses in creating a
                    well-optimized profile that accurately represents their
                    products and services. This includes optimizing the
                    profile's information, images, and videos to enhance its
                    search engine visibility. Furthermore, he helps businesses
                    improve their online reputation by managing their reviews,
                    responding to customer inquiries, and creating engaging
                    posts that attract potential customers.
                  </p>
                </div>

                <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 text-m-center d-flex justify-content-center align-items-center">
                  <img
                    loading="lazy"
                    src={gmbrankingexpert}
                    alt="Google My Business Expert"
                    title=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container-fluid">
          <div class="row medium-padding120 bg-border-color d-flex align-items-stretch">
            <div class="container">
              <div class="row d-flex align-items-stretch">
                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 md-mb-30 text-m-center d-flex justify-content-center align-items-center">
                  <img
                    loading="lazy"
                    src={GoogleBusiness}
                    alt="Zammy Zaif"
                    title=""
                  />
                </div>

                <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 text-m-center d-flex flex-column justify-content-between">
                  <div class="heading">
                    <h2 class="h2 heading-title mb-0">
                      Dominate your Google Business Profile
                    </h2>
                    <div class="heading-line mb-10">
                      <span class="short-line"></span>
                      <span class="long-line"></span>
                    </div>
                  </div>
                  <p class="text-l-justify mb-10">
                    Maximize the impact of your Google Business Profile with our
                    comprehensive optimization services. Our team of specialists
                    will enhance every aspect of your profile to help you
                    attract more customers, boost your visibility in search
                    results, and establish a positive online reputation.
                  </p>
                  <p class="text-l-justify mb-0">
                    Through our strategic approach, we'll help your profile
                    stand out from the competition. Our experts will highlight
                    your unique selling points, feature positive testimonials,
                    and craft engaging posts that resonate with your target
                    audience. Take charge of your online presence and become an
                    industry leader with a captivating and conversion-oriented
                    Google Business Profile.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div
          className="container-fluid acheivement-rank"
          style={{ position: "relative" }}
        >
          <div className="row medium-padding120">
            <div className="container">
              <div className="row mb10">
                <div className="col-lg-12 col-md-12">
                  <div className="heading align-center">
                    <span>Our First Rank Result Speak for us</span>
                    <h2 className="h2 heading-title mb-0">
                      1st Rank Result in 45 Days
                    </h2>
                    <div className="heading-line mb-10">
                      <span className="short-line"></span>
                      <span className="long-line"></span>
                    </div>
                    <p className="mb-10">
                      Google Ranking Services specializes in achieving the first
                      rank on Google's SERP for clients locally & globally. We
                      offer comprehensive services, including competitor
                      analysis, strategic content creation, high-quality
                      backlinks, GBP optimization & on-page technical
                      optimization tailored to align with Google's algorithms.
                    </p>
                  </div>
                </div>
              </div>

              <OwlCarousel className="owl-theme" {...options}>
                <div className="item">
                  <img
                    className="gallery-img"
                    src={propertybuyer}
                    alt="First Rank Result"
                    title="Our successful Google Ranking Services consistently secure first place in London."
                  />
                </div>
                <div className="item">
                  <img
                    className="gallery-img"
                    src={googlebusiness1}
                    alt="First Rank Result"
                    title="Our track record in Google ranking services consistently achieves first-rank results in Louisiana."
                  />
                </div>
                <div className="item">
                  <img
                    className="gallery-img"
                    src={shingle}
                    alt="First Rank Result"
                    title="Our demonstrated success in Google Ranking Services has resulted in securing the first position in Los Angeles."
                  />
                </div>
                <div className="item">
                  <img
                    className="gallery-img"
                    src={progressiveac}
                    alt="First Rank SEO Service"
                    title="We have achieved 1st rank for HVAC contractors in North Hollywood, Los Angeles."
                  />
                </div>
                <div className="item">
                  <img
                    className="gallery-img"
                    src={realestate}
                    alt="First Rank Result"
                    title="Our successful Google Ranking Services consistently secure first-place results in Toronto, Canada."
                  />
                </div>
                <div className="item">
                  <img
                    className="gallery-img"
                    src={dentru}
                    alt="First Rank Result"
                  />
                </div>
                <div className="item">
                  <img
                    className="gallery-img"
                    src={roycleeves}
                    alt="First Rank Result"
                    title="Our successful Google ranking services secured first place in Kitchener, ON, Canada."
                  />
                </div>
                <div className="item">
                  <img
                    className="gallery-img"
                    src={dentru1}
                    alt="First Rank Result"
                  />
                </div>
                <div className="item">
                  <img
                    className="gallery-img"
                    src={studio7rk}
                    alt="First Rank Result"
                  />
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      <section>
        <section>
          <div class="container-fluid">
            <div class="row">
              <div class="seo-score scrollme" style={{ width: "100%" }}>
                <div class="container">
                  <div class="row">
                    <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-xs-12 col-sm-12">
                      <div class="seo-score-content align-center">
                        <div class="heading align-center">
                          <h3 class="h3 heading-title">
                            Looking for More Traffic & Leads?
                          </h3>
                          <p class="heading-text">
                            We have helped 38 top brands in the world and 250+
                            local brands for the last 14 years.
                          </p>
                        </div>

                        <div class="seo-score-form">
                          <form
                            class="input-inline"
                            method="post"
                            action="https://bamstechnologies.org/mailer/it2-leads.php"
                          >
                            <div class="row">
                              <div class="col-lg-5 no-padding col-md-12 col-xs-12 col-sm-12">
                                <input
                                  name="web"
                                  class="input-dark site"
                                  placeholder="Type in your Website URL*"
                                  required
                                />
                              </div>
                              <div class="col-lg-3 no-padding col-md-12 col-xs-12 col-sm-12">
                                <input
                                  name="phone"
                                  class="input-dark phone"
                                  placeholder="Your Phone*"
                                  type="number"
                                  required
                                />
                              </div>
                              <div class="col-lg-4 no-padding col-md-12 col-xs-12 col-sm-12">
                                <input
                                  name="email"
                                  class="input-dark e-mail"
                                  placeholder="Your Email*"
                                  type="email"
                                  required
                                />
                              </div>

                              <div class="col-lg-12 no-padding col-md-12 col-xs-12 col-sm-12">
                                <input
                                  style={{
                                    borderRadius: "50px",
                                    paddingTop: "10px",
                                  }}
                                  name="text"
                                  class="input-dark site"
                                  placeholder="Subject"
                                  required
                                />
                              </div>
                            </div>
                            <input type="hidden" name="page_url" value="" />
                            <div
                              class=""
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                data-sitekey="6LcsApkhAAAAAMYgkxg_Du9jRbCXlHuJbCanu8FU"
                                required
                                class="g-recaptcha"
                              ></div>
                            </div>

                            <button class="btn btn-medium btn--green btn-hover-shadow">
                              <span class="text">Enquiry Now!</span>
                              <span class="semicircle"></span>
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="images">
                  {/* <img loading="lazy" src={seoscore1} alt="image" style={{opacity:1, top:'-10px', paddingTop:'300px'}} /> */}
                  <img
                    loading="lazy"
                    src={seoscore2}
                    alt="image"
                    style={{ opacity: 1, top: "-10px", paddingTop: "540px" }}
                  />
                  <img
                    loading="lazy"
                    src={seoscore3}
                    alt="image"
                    style={{ opacity: 1, top: "-10px", paddingTop: "350px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section>
        <div class="container-fluid" id="gmb-ranking-ptimization">
          <div class="row medium-padding120 bg-border-color">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 md-mb-30 text-m-center">
                  <div class="heading">
                    <span>Starts from $350 Per Profile</span>
                    <h2 class="h2 heading-title mb-0">
                      GMB Ranking Optimization Service
                    </h2>
                    <div class="heading-line mb-10">
                      <span class="short-line"></span>
                      <span class="long-line"></span>
                    </div>
                  </div>
                  <p class="text-l-justify mb-10">
                    Boost your ranking in local search results with our
                    affordable optimization services. Starting from just $350
                    per profile, we'll work tirelessly to enhance your
                    visibility and attract more local customers. Our team of
                    experts will analyze your Google Business profile and
                    implement proven strategies to improve its performance.
                  </p>
                  <p class="text-l-justify mb-10">
                    With our optimization techniques, we'll optimize your
                    profile's information, images, and reviews to increase its
                    relevance and credibility in local search rankings. We'll
                    also focus on enhancing your online reputation through
                    effective review management and engagement with customers.
                  </p>
                  <p class="text-l-justify mb-10">
                    Invest in our cost-effective optimization services and watch
                    your business climb the ranks in local search results. Don't
                    miss out on valuable local customers – let us help you
                    maximize your online presence and drive more traffic to your
                    business.
                  </p>
                  <a
                    class="btn btn-medium btn--green btn-hover-shadow js-window-popup"
                    href="#"
                  >
                    <span class="text">Enquire Now</span>
                    <span class="semicircle"></span>
                  </a>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-m-center">
                  <img
                    loading="lazy"
                    src={googleoptimization}
                    alt="Zammy Zaif"
                    title=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-fluid">
          <div class="row medium-padding120">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-sm-12 col-xs-12 md-mb-30 text-m-center">
                  <div class="heading align-center mb-20">
                    <h2 class="h2 heading-title mb-0">
                      How Our GMB & GBP Optimization Service Works
                    </h2>
                    <div class="heading-line mb-0">
                      <span class="short-line"></span>
                      <span class="long-line"></span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb40">
                    <div
                      className="fbox-4 pc-25 mb-50 wow fadeInUp"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div class="fbox-ico">
                        <div class="ico-60 shape-ico violet-red-color">
                          <img
                            class="ico-bkg"
                            src={localsitation}
                            alt="Local Citation Audit"
                            title=""
                          />
                          <span class="flaticon-double-click"></span>
                        </div>
                      </div>

                      <div class="fbox-txt">
                        <h5 class="h5-md">Step 1 : Local Citation Audit</h5>

                        <p class="p-lg">
                          We conduct a thorough local citation audit to identify
                          any inconsistencies in your business information
                          across online directories.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb40">
                    <div
                      className="fbox-4 pc-25 mb-50 wow fadeInUp"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div class="fbox-ico">
                        <div class="ico-60 shape-ico violet-red-color">
                          <img
                            class="ico-bkg"
                            src={gmbseo}
                            alt="Find In-consistent NAP"
                            title=""
                          />
                          <span class="flaticon-folder-3"></span>
                        </div>
                      </div>

                      <div class="fbox-txt">
                        <h5 class="h5-md">Step 2 : Find In-consistent NAP</h5>

                        <p class="p-lg">
                          Our team diligently finds and rectifies any
                          inconsistent Name, Address, and Phone (NAP) details to
                          ensure accurate and consistent listings.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb40">
                    <div
                      className="fbox-4 pc-25 mb-50 wow fadeInUp"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div class="fbox-ico">
                        <div class="ico-60 shape-ico violet-red-color">
                          <img
                            class="ico-bkg"
                            src={localseo}
                            alt="Competitors Citation"
                            title=""
                          />
                          <span class="flaticon-puzzle"></span>
                        </div>
                      </div>

                      <div class="fbox-txt">
                        <h5 class="h5-md">Step 3 : Competitors Citation</h5>

                        <p class="p-lg">
                          We analyze your competitors' citations to identify
                          opportunities for improvement and stay ahead in local
                          search results.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb40">
                    <div
                      className="fbox-4 pc-25 mb-50 wow fadeInUp"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div class="fbox-ico">
                        <div class="ico-60 shape-ico violet-red-color">
                          <img
                            class="ico-bkg"
                            src={localbusiness}
                            alt="Prepare Citation List"
                            title=""
                          />
                          <span class="flaticon-share"></span>
                        </div>
                      </div>

                      <div class="fbox-txt">
                        <h5 class="h5-md">Step 4 : Prepare Citation List</h5>

                        <p class="p-lg">
                          Our experts prepare a comprehensive citation list,
                          ensuring your business is listed accurately on
                          relevant directories and platforms.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb40">
                    <div
                      className="fbox-4 pc-25 mb-50 wow fadeInUp"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div class="fbox-ico">
                        <div class="ico-60 shape-ico violet-red-color">
                          <img
                            class="ico-bkg"
                            src={richmedia}
                            alt="Rich Media Citation"
                            title=""
                          />
                          <span class="flaticon-share"></span>
                        </div>
                      </div>

                      <div class="fbox-txt">
                        <h5 class="h5-md">Step 5 : Rich Media Citation</h5>

                        <p class="p-lg">
                          We enhance your online presence with rich media
                          citations, including images and videos, to make your
                          business stand out from the competition.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb40">
                    <div
                      className="fbox-4 pc-25 mb-50 wow fadeInUp"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div class="fbox-ico">
                        <div class="ico-60 shape-ico violet-red-color">
                          <img
                            class="ico-bkg"
                            src={seoreport}
                            alt="White Label Report"
                            title=""
                          />
                          <span class="flaticon-share"></span>
                        </div>
                      </div>

                      <div class="fbox-txt">
                        <h5 class="h5-md">Step 6 : White Label Report</h5>

                        <p class="p-lg">
                          You'll receive a detailed white-label report outlining
                          the work done, improvements made, and the overall
                          impact of our Google My Business Optimization service.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row medium-padding120 bg-orangedark-color">
            <div className="container">
              <div
                className="row"
                style={{ display: "flex", alignItems: "stretch" }} // Flexbox for equal height
              >
                <div
                  className="col-lg-4 col-md-5 col-sm-12 col-xs-12 md-mb-30 text-m-center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }} // Centering the image
                >
                  <img
                    loading="lazy"
                    src={googlemybusiness}
                    alt="Google My Business Ranking Service"
                    title=""
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>

                <div
                  className="col-lg-8 col-md-7 col-sm-12 col-xs-12 text-m-center"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }} // Centering text and ensuring equal height
                >
                  <div className="heading">
                    <h2 className="h2 heading-title mb-0 c-white">
                      Google Business Profile Ranking Service
                    </h2>
                    <div className="heading-line mb-10">
                      <span className="short-line bg-yellow-color"></span>
                      <span className="long-line bg-yellow-color"></span>
                    </div>
                  </div>
                  <p className="text-l-justify mb-10 c-white">
                    Elevate your Google My Business listing ranking with our
                    specialized service. Our team of experts understands the
                    significance of securing a top position in search results,
                    and we're here to help you achieve that. With our proven
                    strategies and in-depth knowledge of Google's algorithms,
                    we'll optimize your listing to enhance its visibility,
                    attract organic traffic, and outshine your competitors.
                  </p>
                  <p className="text-l-justify mb-0 c-white">
                    Our comprehensive approach includes optimizing your business
                    information, selecting relevant categories, managing
                    reviews, implementing targeted keywords, and creating
                    engaging content. By leveraging our Google My Business
                    listing ranking service, you'll gain a competitive
                    advantage, increase your online presence, and attract more
                    customers to your business. Don't miss out on the
                    opportunity to dominate local search results and experience
                    substantial growth - entrust your Google My Business listing
                    ranking to our expert team.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container-fluid">
          <div class="row">
            <div class="background-mountains medium-padding120 scrollme" style={{width:'100%'}}>
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12 mb-4 mb-lg-0 md-mb-30 text-m-center">
                    <div class="heading mb-0">
                      <h3 class="heading-title mb-0">
                        GMB Local Citation Building Service
                      </h3>
                      <div class="heading-line mb-10">
                        <span class="short-line"></span>
                        <span class="long-line"></span>
                      </div>
                    </div>

                    <p class="text-l-justify mb-0">
                      Take advantage of our GMB Local Citation Building Service
                      to strengthen your online presence. We understand the
                      importance of accurate and consistent business information
                      across various online directories and platforms. Our
                      expert team will meticulously build relevant citations to
                      establish your business as a trusted and authoritative
                      entity in your industry.
                    </p>
                    <p class="text-l-justify mb-0">
                      Through our service, we'll conduct thorough research to
                      identify authoritative directories and platforms that are
                      relevant to your business. We'll ensure your business
                      information, including Name, Address, and Phone (NAP)
                      details, is listed accurately and consistently across
                      these platforms. By leveraging our GMB Local Citation
                      Building Service, you'll not only improve your local
                      search rankings but also enhance your online reputation.
                      Increased visibility, credibility, and customer trust
                      await you as we build a robust network of citations for
                      your business. Don't underestimate the power of accurate
                      citations - let us help you establish a strong online
                      presence that drives more customers to your door.
                    </p>
                  </div>

                  <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12 text-m-center">
                    <img
                      loading="lazy"
                      src={localsitationbuilding}
                      alt="GMB Local Citation Building Service"
                      title=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
  <div class="container-fluid">
    <div class="row medium-padding120 bg-border-color">
      <div class="container">

        <div class="heading mb40 align-center">
          <span class="faq-label">FAQ</span>
          <h3 class="h3 heading-title mb-0">Frequently Asked Questions</h3>

          <div class="heading-line">
            <span class="short-line"></span>
            <span class="long-line"></span>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 md-mb-30">
            <img src={faq} alt="faq" title="" class="faq-image"/>
          </div>

          <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div id="accordion" class="crumina-module crumina-accordion">
              
              <div class="card accordion-panel mb-20">
                <div class="card-header panel-heading" id="headingOne">
                  <button class="btn-link accordion-heading" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <span class="icon">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                    <span class="title">Is your Phone Number not displaying?</span>
                  </button>
                </div>
                <div id="collapseOne" class="panel-collapse collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body">
                    <div class="panel-info">
                      If your phone number is not displaying on your Google My Business listing, there could be a few reasons for this issue...
                    </div>
                  </div>
                </div>
              </div>

              <div class="card accordion-panel mb-20">
                <div class="card-header panel-heading" id="headingTwo">
                  <button class="btn-link accordion-heading collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                    <span class="icon">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                    <span class="title">How to claim your Business?</span>
                  </button>
                </div>
                <div id="collapseTwo" class="panel-collapse collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">
                    <div class="panel-info">
                      Claiming your business on Google My Business is a straightforward process. Start by visiting the Google My Business website...
                    </div>
                  </div>
                </div>
              </div>

              <div class="card accordion-panel mb-20">
                <div class="card-header panel-heading" id="headingThree">
                  <button class="btn-link accordion-heading collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                    <span class="icon">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                    <span class="title">Do you want to change your Location?</span>
                  </button>
                </div>
                <div id="collapseThree" class="panel-collapse collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div class="card-body">
                    <div class="panel-info">
                      If you need to change the location of your business on your Google My Business listing, you can do so by following these steps...
                    </div>
                  </div>
                </div>
              </div>

              <div class="card accordion-panel mb-20">
                <div class="card-header panel-heading" id="headingFour">
                  <button class="btn-link accordion-heading collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                    <span class="icon">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                    <span class="title">What to do if your Business Listing is not Optimized?</span>
                  </button>
                </div>
                <div id="collapseFour" class="panel-collapse collapse" aria-labelledby="headingFour" data-parent="#accordion">
                  <div class="card-body">
                    <div class="panel-info">
                      If your business listing on Google My Business is not optimized, you may be missing out on valuable opportunities to attract customers...
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  );
}
