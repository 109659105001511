import React, { useState ,useEffect} from "react";
import { Helmet } from "react-helmet";
import 'owl.carousel/dist/assets/owl.carousel.css'; 
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import OwlCarousel from 'react-owl-carousel';

export default function SeoServices() {

  const [pageUrl, setPageUrl] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted");
  };

 
  useEffect(() => {
    // Set the current page URL
    setPageUrl(window.location.href);

    // Load the reCAPTCHA script
    const loadReCaptcha = () => {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=6LcsApkhAAAAAMYgkxg_Du9jRbCXlHuJbCanu8FU`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    loadReCaptcha();
  }, []);

  const handleRecaptcha = (value) => {
    console.log("Captcha value:", value);
    // You can handle the reCAPTCHA value here (e.g., send it to your server)
  };
    const accordionData = [
      {
        question: "Why is ranking #1 on Google so important?",
        answer: "Google Rank 1 matters because it positions your site at the top of search results, increasing visibility, attracting more organic traffic, and converting visitors into customers."
      },
      {
        question: "Can #1 rank boost sales?",
        answer: "Obtaining Google Rank 1 enhances your brand visibility, attracting more qualified leads and converting them into customers through trust and authority."
      },
      {
        question: "What makes Google #1 rank more effective?",
        answer: "Studies show that most users click on one of the top three search results, which receive the majority of organic traffic and sales leads."
      },
      {
        question: "Does Google #1 benefit business?",
        answer: "Google's #1 ranking positions a business as a leading authority in its industry, building essential trust and credibility for long-term success."
      },
      {
        question: "How can Google ranking services help businesses get more potential customers?",
        answer: "Ranking services help businesses attract more customers by optimizing their online presence to appear at the top of search results, increasing organic traffic and brand credibility."
      }
    ];
  
  
  return (
    <>
      <Helmet>
        <title>About Us</title>
      </Helmet>

      <section
        className="page-title page-title-overlay bg-cover overflow-hidden"
        style={{ backgroundImage: "url('/images/background/about.jpg')" }}>
      
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h1 className="text-white position-relative">
              Google Ranking Services
                <span className="watermark-sm">Google Ranking Services</span>
              </h1>
              <p className="text-white pt-4 pb-4">Zammy Saif</p>
            </div>
            <div className="col-lg-3 ml-auto align-self-end">
              <nav
                className="position-relative zindex-1"
                aria-label="breadcrumb"
              ></nav>
            </div>
          </div>
        </div>
      </section>

      <section style={{ display: 'flex', alignItems: 'center', padding: '20px' }}>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 md-mb-30 text-m-center">
                  <img
                    loading="lazy"
                    src="assets/images/Contact/get-more-calls.gif"
                    alt="Seo Services"
                  />
      </div>
      <div style={{ flex: '1', padding: '20px' }}>
        <div className="heading">
          <h2 className="h2 heading-title mb-0">
            Boost your Website Ranking
          </h2>
          <div className="heading-line">
            <span className="short-line"></span>
            <span className="long-line"></span>
          </div>
        </div>
        <p className="text-l-justify mb-20" style={{ textAlign: 'justify' }}>          Our specialized SEO services are carefully designed to propel your online visibility with a multi-pronged approach. We'll enhance your Google Business Profile to secure coveted first-rank placement, meticulously optimize your website to dominate search results, and develop cutting-edge strategies that leave your competition trailing behind. By prioritizing a user-centric, keyword-rich experience, we'll ensure an increase in high-quality organic traffic, driving more inquiries and those all-important sales calls.
        </p>
        <a href="contact-zammy-zaif.php" className="btn btn-medium btn--secondary">
          <span className="text">Get Quote</span>
          <span className="semicircle"></span>
        </a>
      </div>
    </section>
      <section>
      <div className="container-fluid achievement-rank" style={{ position: 'relative' }}>
        <div className="row medium-padding120">
          <div className="container">
            <div className="row mb10">
              <div className="col-lg-12 col-md-12">
              <div className="heading align-center" style={{ textAlign: 'center' }}>
  <span>Our First Rank Result Speak for us</span>
  <h2 className="h2 heading-title mb-0">Expert in Google Ranking Services</h2>
  <div className="heading-line mb-10" style={{ display: 'inline-block' }}>
    <span className="short-line" style={{ display: 'block' }}></span>
    <span className="long-line" style={{ display: 'block' }}></span>
  </div>
  <p className="mb-10" style={{ textAlign: 'center' }}>
    Google Ranking Services specializes in achieving the first rank on Google's SERP for clients locally & globally. We offer comprehensive services, including competitor analysis, strategic content creation, high-quality backlinks, GBP optimization & on-page technical optimization tailored to align with Google's algorithms. Our team of Google Rank Experts attracts the right audience for your business with our specialized SEO ranking services.
  </p>
</div>
              </div>
            </div>

            <OwlCarousel 
              className="owl-theme" 
              loop 
              margin={10} 
              nav 
              items={3}
              display="flex"
              justify-content= "center"
            >
              <div className="item service-block-two wow fadeInUp">
                <img 
                  className="gallery-img" 
                  src="assets/images/SeoServices/property-buyers-in-london-result.webp" 
                  alt="First Rank Result" 
                  title="Our successful Google Ranking Services consistently secure first place in London."
                />
              </div>
              
              <div className="item service-block-two wow fadeInUp">
                <img 
                  className="gallery-img" 
                  src="assets/images/SeoServices/google-business-ranking-performance.webp" 
                  alt="First Rank Result" 
                  title="Our track record in Google ranking services consistently achieves first-rank results in Louisiana."
                />
              </div>

              <div className="item service-block-two wow fadeInUp">
                <img 
                  className="gallery-img" 
                  src="assets/images/SeoServices/shingle-roofing-experts-los-angeles-result.webp" 
                  alt="First Rank Result" 
                  title="Our demonstrated success in Google Ranking Services has resulted in securing the first position in Los Angeles."
                />
              </div>

              <div className="item service-block-two wow fadeInUp">
                <img 
                  className="gallery-img" 
                  src="assets/images/SeoServices/progressive-ac-services-north-hollywood.png" 
                  alt="First Rank Seo Service" 
                  title="We have achieved 1st rank for HVAC contractors in North Hollywood, Los Angeles."
                />
              </div>

              <div className="item service-block-two wow fadeInUp">
                <img 
                  className="gallery-img" 
                  src="assets/images/SeoServices/realestate-coach.webp" 
                  alt="First Rank Result" 
                  title="Our successful Google Ranking Services consistently secure first-place results in Toronto, Canada."
                />
              </div>

              <div className="item service-block-two wow fadeInUp">
                <img 
                  className="gallery-img" 
                  src="assets/images/SeoServices/dentru.webp" 
                  alt="First Rank Result" 
                  title=""
                />
              </div>

              <div className="item service-block-two wow fadeInUp">
                <img 
                  className="gallery-img" 
                  src="assets/images/SeoServices/roy-cleeves.webp" 
                  alt="First Rank Result" 
                  title="Our successful Google ranking services secured first place in Kitchener, ON, Canada."
                />
              </div>

              <div className="item service-block-two wow fadeInUp">
                <img 
                  className="gallery-img" 
                  src="assets/images/SeoServices/dentru-gurgaon.webp" 
                  alt="First Rank Result" 
                  title=""
                />
              </div>

              <div className="item service-block-two wow fadeInUp">
                <img 
                  className="gallery-img" 
                  src="assets/images/SeoServices/studio-7rk.webp" 
                  alt="First Rank Result" 
                  title=""
                />
              </div>
            </OwlCarousel>

          </div>
        </div>
      </div>
    </section>
    <section style={{ backgroundColor: '#333333' }}>
  <div className="row">
    <div className="container">
      <div className="row justify-content-center">
        {/* Move the seoscore1 image above the heading */}
        <div className="images-block" style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
          <img
            src="assets/images/SeoServices/seoscore1.png"
            alt="seascore1"
            className="mail-2"
            style={{
              opacity: "1",
              position: "relative",
              marginBottom: '10px', // Space below the image
            }}
          />
        </div>

        <div className="col-lg-6 col-md-8 col-sm-12">
          <h2 className="subscribe-title" style={{ fontSize: "30px", color: "#fff", marginBottom: "10px", textAlign: "center" }}>
            Looking for More Traffic & Leads?
          </h2>
          <p style={{ color: "#797676", marginBottom: "15px", textAlign: "center", fontSize: "24px" }}>
            We have helped 38 top brands in the world and 250+ local brands for the last 14 years.
          </p>
          
          <div className="col-lg-13" style={{ display: 'flex', width: '100%', marginBottom: '15px' }}>
            <input 
              type="text" 
              className="form-control mb-4" 
              placeholder="Type In Your Website URL" 
              name="name" 
              style={{
                flex: 2, // Make this input take more space
                backgroundColor: '#888888', /* Darker grey background */
                color: '#fff', 
                border: 'none', 
                borderTopLeftRadius: '50px', /* Oval shape for left side */
                borderBottomLeftRadius: '50px',
                padding: '15px 20px', 
                marginRight: '-1px', /* Overlap slightly to remove space */
              }} 
            />
            <input 
              type="number" 
              className="form-control mb-4" 
              placeholder="Your Phone" 
              name="phone" 
              style={{
                flex: 1, 
                backgroundColor: '#888888', 
                color: '#fff', 
                padding: '15px 20px', 
                margin: '0', /* Remove margin */
              }} 
            />
            <input 
              type="text" 
              className="form-control mb-4" 
              placeholder="Your Email" 
              name="email" 
              style={{
                flex: 1, 
                backgroundColor: '#888888', 
                color: '#fff', 
                border: 'none', 
                borderTopRightRadius: '50px', /* Oval shape for right side */
                borderBottomRightRadius: '50px',
                padding: '15px 20px',
                marginLeft: '-1px', /* Overlap slightly to remove space */
              }} 
            />
          </div>

          <div className="col-lg-13" style={{ marginBottom: '15px' }}>
            <input 
              type="text" 
              className="bg-light form-control mb-4" 
              placeholder="Subject" 
              name="subject" 
              style={{
                backgroundColor: '#888888', 
                color: '#fff', 
              }} 
            />
          </div>

          <input type="hidden" name="page_url" value={pageUrl} />
          <div className="row justify-content-center" style={{ marginBottom: '20px', display: 'flex' }}>
            <div className="g-recaptcha" data-sitekey="6LcsApkhAAAAAMYgkxg_Du9jRbCXlHuJbCanu8FU"></div>
          </div>
          <div className="col-12">
            <button type="button" className="btn btn-green" style={{ backgroundColor: '#3CB371' }}>
              Submit Now
            </button>
          </div>
        </div>
      </div>
      
      {/* Left images block */}
      <div className="subscribe scrollme" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
        <div className="images-block" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: '20px' }}>
          <img
            src="assets/images/SeoServices/seoscore3.png"
            alt="gear"
            className="gear"
            style={{ marginBottom: '10px' }} // Space between images
          />
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <img
              src="assets/images/SeoServices/seoscore2.png"
              alt="mail"
              className="mail"
              style={{ marginBottom: '10px' }} // Space below the image
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
            <div className="container-fluid achievement-rank" id="seopricing" style={{ position: 'relative' }}>
                <div className="row medium-padding120 pricing-tables pricing-tables-colored medium-padding120 bg-white-color">
                    <div className="container">
                        <div className="row mb10">
                            <div className="col-lg-12 col-md-12">
                                <div className="heading align-center" style={{ textAlign: 'center' }}>
                                    <span>Local to Global Ranking SEO Services</span>
                                    <h2 className="h2 heading-title mb-0" style={{ textAlign: 'center' }}>Cost of SEO Rank Services</h2>
                                    <div className="heading-line mb-10">
                                        <span className="short-line"></span>
                                        <span className="long-line"></span>
                                    </div>
                                    <p className="mb-10">Specializing in Google Ranking Services to elevate your online presence and drive organic traffic to your website.</p>
                                </div>
                            </div>
                        </div> 
                        <OwlCarousel 
              className="owl-theme" 
              loop 
              margin={10} 
              nav 
              items={4}
              display="flex"
              justify-content= "center"
              
            >
                         <div className="item service-block-two wow fadeInUp" id="cost-of-gbp-local-seo">
  <div className="pricing-tables-item" style={{
      background: '#e33e2b',
      borderRadius: '10px',
      padding: '30px',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      color: '#fff',
      textAlign: 'center'
  }}>
    <a href="#cost-of-gbp-local-seo" className="js-window-popup pricing-title" style={{
        fontSize: "24px",
        fontWeight: "700",
        marginBottom: "20px",
        display: "block",
        width: "100%",
        color: '#fff',
        textDecoration: 'none',
        transition: 'color 0.3s'
    }}>
      GBP Local SEO
    </a>
    
    <ul className="pricing-tables-position" style={{
        listStyleType: 'none',
        padding: '0',
        marginBottom: '20px',
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#fff'
    }}>
      <li className="position-item" style={{ 
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px' 
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>
        45 Days Timeframe
      </li>
      <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>
        5-10 Miles of Area Radius
      </li>
      <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>
        5 Major Category Keywords
      </li>
      <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>
        5 Business Related Keywords
      </li>
      <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>
        1 Google Business Profile
      </li>
      <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>
        Website Ranking
      </li>
    </ul>

    <p className="text-center mb-0" style={{ marginBottom: '10px', fontWeight: '600' }}>Starts from</p>
    <h4 className="rate" style={{
        fontSize: '28px',
        fontWeight: '700',
        marginBottom: '20px'
    }}>$750</h4>

<div className="btn btn-medium btn-border js-window-popup" >
  <span className="text" >Contact Now</span>
                    <span className="semicircle"></span>
  
</div>
  </div>
</div>
              <div className="item service-block-two wow fadeInUp" id="city-based-seo">
              <div className="pricing-tables-item" style={{
      background: "#007BFF",
      borderRadius: '10px',
      padding: '30px',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      color: '#fff',
      textAlign: 'center'
  }}>
    <a href="#cost-of-gbp-local-seo" className="js-window-popup pricing-title" style={{
        fontSize: "24px",
        fontWeight: "700",
        marginBottom: "20px",
        display: "block",
        width: "100%",
        color: '#fff',
        textDecoration: 'none',
        transition: 'color 0.3s'
    }}>City Based SEO</a>
                  
                  <ul className="pricing-tables-position" style={{
        listStyleType: 'none',
        padding: '0',
        marginBottom: '20px',
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#fff'
    }}>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>3-6 Months Timeframe</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>A Particular City or District</li>
                    <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>1st Rank for 25 Keywords</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>1st page Rank for 75 Keywords</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>1 Google Business Profile</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>300 HQ White Hat Backlinks</li>
                  </ul>
                  <p className="text-center mb-0" style={{ marginBottom: '10px', fontWeight: '600' }}>Starts from</p>
                  <h4 className="rate" style={{
        fontSize: '28px',
        fontWeight: '700',
        marginBottom: '20px'
    }}>$999/Month</h4>
                  <div className="btn btn-medium btn-border js-window-popup" >
  <span className="text" >Contact Now</span>
                    <span className="semicircle"></span>
                  </div>
                </div>
              </div>

              <div className="item service-block-two wow fadeInUp" id="state-level-seo">
              <div className="pricing-tables-item" style={{
      background: '#28A745',
      borderRadius: '10px',
      padding: '30px',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      color: '#fff',
      textAlign: 'center'
  }}>
    <a href="#cost-of-gbp-local-seo" className="js-window-popup pricing-title" style={{
        fontSize: "24px",
        fontWeight: "700",
        marginBottom: "20px",
        display: "block",
        width: "100%",
        color: '#fff',
        textDecoration: 'none',
        transition: 'color 0.3s'
    }}>State Level SEO</a>
                 <ul className="pricing-tables-position" style={{
        listStyleType: 'none',
        padding: '0',
        marginBottom: '20px',
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#fff'
    }}>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>6-9 Months Timeframe</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>State Level 1st Rank</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>1st Rank for 25 Keywords</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>1st Page Rank for 125 Key</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>1-5 Google Business Profiles</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>500 HQ White Hat Backlinks</li>
                  </ul>
                  <p className="text-center mb-0" style={{ marginBottom: '10px', fontWeight: '600' }}>Starts from</p>
                  <h4 className="rate" style={{
        fontSize: '28px',
        fontWeight: '700',
        marginBottom: '20px'
    }}>$1350/Month</h4>
                <div className="btn btn-medium btn-border js-window-popup" >
  <span className="text" >Contact Now</span>
                    <span className="semicircle"></span>
                  </div>
                </div>
              </div>

              <div className="item service-block-two wow fadeInUp" id="country-level-seo">
              <div className="pricing-tables-item" style={{
      background: '#FFC107',
      borderRadius: '10px',
      padding: '30px',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      color: '#fff',
      textAlign: 'center'
  }}>
    <a href="#cost-of-gbp-local-seo" className="js-window-popup pricing-title" style={{
        fontSize: "24px",
        fontWeight: "700",
        marginBottom: "20px",
        display: "block",
        width: "100%",
        color: '#fff',
        textDecoration: 'none',
        transition: 'color 0.3s'
    }}>Country Level SEO</a>
                   <ul className="pricing-tables-position" style={{
        listStyleType: 'none',
        padding: '0',
        marginBottom: '20px',
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#fff'
    }}>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>9-12 Months Timeframe</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>Country Level 1st Rank</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>1st Rank for 50 Keywords</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>1st Page Rank for 75 Keywords</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>1-10 Google Business Profiles</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>750 HQ White Hat Backlinks</li>
                  </ul>
                  <p className="text-center mb-0" style={{ marginBottom: '10px', fontWeight: '600' }}>Starts from</p>
                  <h4 className="rate" style={{
        fontSize: '28px',
        fontWeight: '700',
        marginBottom: '20px'
    }}>$1700/Month</h4>
<div className="btn btn-medium btn-border js-window-popup" >
<span className="text" >Contact Now</span>
                  <span className="semicircle"></span>                  </div>
                </div>
              </div>

              <div className="item service-block-two wow fadeInUp" id="global-seo">
              <div className="pricing-tables-item" style={{
      background: '#DC3545',
      borderRadius: '10px',
      padding: '30px',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      color: '#fff',
      textAlign: 'center'
  }}>
    <a href="#cost-of-gbp-local-seo" className="js-window-popup pricing-title" style={{
        fontSize: "24px",
        fontWeight: "700",
        marginBottom: "20px",
        display: "block",
        width: "100%",
        color: '#fff',
        textDecoration: 'none',
        transition: 'color 0.3s'
    }}>Global SEO</a>
                   <ul className="pricing-tables-position" style={{
        listStyleType: 'none',
        padding: '0',
        marginBottom: '20px',
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#fff'
    }}>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>12-24 Months Timeframe</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>Global Level 1st Rank</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>1st Rank for 75 Keywords</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>1st Page Rank for 125 Keys</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>Snippet Featured Results</li>
                     <li className="position-item" style={{
          marginBottom: '10px',
          position: 'relative',
          paddingLeft: '25px'
      }}>
        <span style={{
            position: 'absolute',
            left: '0',
            top: '5px',
            width: '8px',
            height: '8px',
            backgroundColor: '#fff',
            borderRadius: '50%'
        }}></span>1500 HQ White Hat Backlinks</li>
                  </ul>
                  <p className="text-center mb-0" style={{ marginBottom: '10px', fontWeight: '600' }}>Starts from</p>
                  <h4 className="rate" style={{
        fontSize: '28px',
        fontWeight: '700',
        marginBottom: '20px'
    }}>$1500/Month</h4>
                  <div className="btn btn-medium btn-border js-window-popup" >
  <span className="text" >Contact Now</span>
                    <span className="semicircle"></span>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
        </section>
        <section>
        <div class="container-fluid">
		<div class="row medium-padding120 bg-blue-color" style={{backgroundColor:"#4169E1"}}>
			<div class="container">

				<div class="row">
                    
                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 md-mb-30 text-m-center">
                        <img loading="lazy" src="assets/images/SeoServices/image.png" alt="Google's No.1 - Your Business Pride" title=""/>
                    </div>
                    
                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-m-center">
                        <div class="heading">
                            <h3 class="h3 heading-title c-white mb-0">Google's No.1 - Your Business Pride</h3>
                            <div class="heading-line mb-10">
								<span class="short-line bg-yellow-color"></span>
								<span class="long-line bg-yellow-color"></span>
							</div>
                        </div>
                        <p class="text-l-justify c-white mb-20">Google's #1 ranking as a search engine directly benefits businesses because it drives immense traffic to websites that appear at the top of search results. High visibility on Google translates to increased brand awareness, more potential customers finding your website, and ultimately a boost in lead generation and sales. Essentially, strong Google search performance equates to an inherent competitive advantage and signifies success in the digital landscape, making it a point of pride for any business.</p>
                        <div class="btn btn-medium btn--secondary js-window-popup">
									<span class="text">Contact Now</span>
									<span class="semicircle"></span>
								</div>
                    </div>


				</div>
			</div>
		</div>
	</div>

        </section>
<section>
<div class="container-fluid">
		<div class="row medium-padding120">
			<div class="container">
				<div class="row">
                    
                    <div class="col-lg-7 col-sm-12 col-xs-12 md-mb-30 text-m-center">
				<div class="heading">
                    <span>GBP is Your Key to Attract Local Audience</span>
					<h3 class="h3 heading-title mb-0">Grow Leads with Google Business Profile</h3>
					<div class="heading-line mb-10">
						<span class="short-line"></span>
						<span class="long-line"></span>
					</div>
				</div>
                <div class="">
                    <p class="text-l-justify mb-10">Our team specializes in optimizing your Google Business Profile to maximize visibility and attract qualified leads in your local area. We'll ensure your profile is complete, accurate, and showcases positive customer reviews. Additionally, we'll strategically use Google Posts, messaging features, and targeted Q&A responses to build trust and convert searchers into customers. Our service helps you dominate local search results and drive a consistent stream of warm leads directly to your business.</p>
                    
                    <div class="btn btn-medium btn--green js-window-popup">
									<span class="text">Contact Now</span>
									<span class="semicircle"></span>
								</div>
                </div>
			</div>
                    
                    <div class="col-lg-5 col-sm-12 col-xs-12 text-m-center">
				<img loading="lazy" src="assets/images/SeoServices/grow-with-google-business-profile.gif" alt="grow with google" title=""/>
			</div>
                    
				</div>
			</div>
		</div>
	</div>

  <div class="container-fluid">
		<div class="row medium-padding120 bg-border-color text-m-center">
			<div class="container">
				<div class="row">
                    
                    <div class="col-lg-4 col-sm-12 col-xs-12 md-mb-30 text-m-center">
				<img loading="lazy" src="assets/images/SeoServices/boost-website-visibility.svg" alt="Benefits for Google's First Rank" title="Benefits of Ranking #1 on Google for Your B2B and B2C Organization"/>
			</div>
                    
			<div class="col-lg-8 col-sm-12 col-xs-12">
				<div class="heading">
					<h3 class="h3 heading-title mb-0">Boost Website Visibility - Accelerate Sales</h3>
					<div class="heading-line">
						<span class="short-line"></span>
						<span class="long-line"></span>
					</div>
				</div>
                <div class="">
                    <p class="text-l-justify mb-10">The visibility of a website is one of the most important factors for running a successful business. Accelerate your sales by boosting your website's visibility. Our data-driven optimization strategies transform your website presence into a high-performing sales machine.  With customized design enhancements, persuasive copywriting and targeted A/B testing, we focus on relevant business-enquiry keywords to lead you towards sales qualified leads.</p>
                    <p class="text-l-justify mb-0">This increased visibility will take your business website to the top of search results, driving more organic traffic and has high chances of converting those leads into customers. By attracting more business, our optimization method builds strong credibility to help you stand out among your competitors easily. This active engagement will earn the trust of your targeted audience, resulting in more clicks. Choose our ranking services to quickly increase your business growth with high number of sales.</p>
                </div>
			</div>
                    
                    
				</div>
			</div>
		</div>
	</div>
    
    
</section>
<section>
<div class="container-fluid">
		<div class="row medium-padding120 bg-orange-color">
			<div class="container">
				<div class="row">
                    
                    
                    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 md-mb-30 text-m-center">
                        <div class="heading">
                            <h2 class="h2 heading-title c-white mb-0">Dominating Google Search Rankings</h2>
                            <div class="heading-line mb-10">
								<span class="short-line bg-yellow-color"></span>
								<span class="long-line bg-yellow-color"></span>
							</div>
                        </div>
                        <p class="text-l-justify c-white mb-10">Dominating Google search rankings means owning the digital spotlight and becoming the go-to source for customers. Higher rankings establish you as an industry authority, fuel traffic to your website, and ultimately accelerate business growth. It's not just about showing up; it's about being where everyone's looking.</p>
                        <p class="text-l-justify c-white mb-0">Step into the digital spotlight by dominating Google search rankings. Higher rankings mean increased visibility, establishing you as an industry authority and driving traffic to your website. Our strategies ensure you're not just showing up but standing out where everyone's looking. Be the headliner on the brightly lit stage of Google search results.</p>
                    </div>

                    
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-m-center">
                        <img loading="lazy" src="assets/images/SeoServices/dominating-search-rankings.svg" alt="White Hat SEO Process" title="Best Google SEO Ranking Services Providers in 2023"/>
                    </div>
				</div>
			</div>
		</div>
	</div>
    
    
    <div class="container">
		<div class="row medium-padding120">
            
            <div class="col-lg-6 col-sm-12 col-xs-12 md-mb-30 text-center">
				<img loading="lazy" src="assets/images/SeoServices/website-1st-rank-result.gif" alt="Website 1st Rank Result" title="We are experts in achieving website first rank result"/>
			</div>
            
			<div class="col-lg-6 col-sm-12 col-xs-12 text-m-center">
				<div class="heading mb-10">
					<h3 class="h3 heading-title mb-0">Website 1st Rank Search Result</h3>
					<div class="heading-line">
						<span class="short-line"></span>
						<span class="long-line"></span>
					</div>
				</div>
                <div class="">
                    <p class="text-l-justify mb-10">Benefit from our seasoned SEO experts who are committed to delivering Website Ranking Optimization services aimed at securing the prestigious No.1 position on Google. With a track record dating back to 2008, we've consistently assisted businesses in dominating search results and reaching the summit of Google. Harness the potency of top-tier outcomes through our unwavering SEO services. Since 2014, we've enabled businesses of all scales to conquer search rankings and secure coveted first-page placements on Google.</p>
                    <p class="text-l-justify mb-0">With our unwavering dedication to tangible results, we assure you of substantial sales growth and heightened online visibility. Join forces with us to elevate your business to the pinnacle of Google's rankings.</p>
                </div>
			</div>

		</div>
	</div>
</section>
<section>
  <div className="container-fluid">
    <div className="row medium-padding120" style={{ backgroundColor: "#F15B26" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 md-mb-30 text-m-center">
            <div className="heading">
              <span className="subhead" style={{ color: "#fcd846" }}>
                Transform Your Google Presence: Be Found, Be Chosen
              </span>
              <h3 className="h3 heading-title c-white mb-0">Revolutionize with Google SEO Service</h3>
              <div className="heading-line mb-15">
                <span className="short-line bg-yellow-color"></span>
                <span className="long-line bg-yellow-color"></span>
              </div>
            </div>
            <p className="mb-10 text-l-justify c-white">
              Focusing your business growth with Google offers several compelling advantages. Google's dominance in search makes mastering Search Engine Optimization (SEO) critical – aligning your website with Google's algorithms boosts visibility and attracts organic traffic. Moreover, Google provides an array of invaluable free tools: Google Analytics helps you understand website performance, Google My Business enhances local search presence, and Google Search Console aids in technical SEO management. Leveraging these resources alongside strategic SEO practices positions your business to thrive within the vast Google ecosystem, driving increased traffic, leads, and ultimately sustainable growth.
            </p>
            <a href="contact-zammy-zaif.php" className="btn btn-medium btn--dark btn-hover-shadow">
              <span className="text">Contact Us</span>
              <span className="semicircle"></span>
            </a>
          </div>

          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 text-m-center">
            <img loading="lazy" src="assets/images/SeoServices/revolutionize-with-google-seo-services.svg" alt="Google SEO Service" title="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
<div class="container-fluid">
		<div class="row medium-padding120 bg-border-color">
			<div class="container">
				<div class="row">
                    
                <div class="col-lg-5 col-sm-12 col-xs-12 md-mb-30 text-m-center">
				<img class="choose-brand" loading="lazy" src="assets/images/SeoServices/specialised-seo-ranking-services.webp" alt="Specialised SEO Ranking Services" title=""/>
			</div>
                    
			<div class="col-lg-7 col-sm-12 col-xs-12">
				<div class="heading">
					<h3 class="h3 heading-title mb-0">Specialised SEO Ranking Services</h3>
					<div class="heading-line mb-10">
						<span class="short-line"></span>
						<span class="long-line"></span>
					</div>
				</div>
                <div class="">
                    <ul class="ulli">
                        <li style={{color:"black"}}><b>Local Search:</b> This targets individuals within a 5 to 15-mile radius. Optimizing for local SEO will ensure your Google Business profile appears to those in your immediate vicinity. This process typically takes 3 months.</li>
                        <li style={{color:"black"}}><b>City-Specific Search:</b> For instance, if someone searches from Los Angeles, they'll find your website, but searches from other cities won't yield your site. This process usually takes 6 months.</li>
                        <li style={{color:"black"}}><b>State Level SEO:</b> We optimize your website to appear in searches throughout the entire state, enhancing visibility for your product category. This typically requires 9 months.</li>
                        <li style={{color:"black"}}><b>National SEO:</b> By optimizing your website at a country level, we ensure people across the nation find your site when searching for your product category. This process generally takes 12 months.</li>
                        <li style={{color:"black"}}><b>Global SEO:</b> This involves optimizing your website to appear in search results worldwide, targeting users searching for relevant keywords from anywhere. Global SEO typically takes 12 to 16 months.</li>
                    </ul>
                </div>
			</div>
                    
                    
				</div>
			</div>
		</div>
	</div>
</section>
     <section>
     <div class="container">
		<div class="row medium-padding120">
            
            <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 md-mb-30 text-m-center">
				<div class="heading">
                            <span>ZAMMY ZAIF</span>
                            <h3 class="h3 heading-title mb-0">Win Google Algorithm & Win Competition</h3>
                            <div class="heading-line mb-10">
                                <span class="short-line"></span>
                                <span class="long-line"></span>
                            </div>
                        </div>
                <p class="mb-0 text-l-justify">Winning Google's algorithm and emerging victorious in competitions requires a multifaceted approach focused on high-quality, user-centric content. Prioritize original, in-depth content that addresses searcher intent while adhering to Google's quality guidelines. Employ technical SEO best practices, ensuring your site is fast, mobile-friendly, and crawlable. Build authority through reputable backlinks from relevant websites. Stay updated on algorithm changes, and don't engage in manipulative tactics that violate Google's guidelines. Remember that genuine value for users lies at the heart of long-term success.</p>
			</div>

			<div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 text-m-center">
                <img loading="lazy" src="assets/images/SeoServices/win-google-algorithm.webp" alt="Win Google Algorithm & Win Competition" title=""/>
			</div>

		</div>
	</div>
     </section>
     <section  style={{ backgroundColor: "#FFA500" }}>
     <div class="container-fluid">
		<div class="row">
			<div class="testimonial-slider scrollme">
				<div class="container">
					<div class="row">
						<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div class="heading">
								<h4 class="h4 heading-title mb-0" style={{fontSize:"26px"}}>Happy Clients
									About Us</h4>
								<div class="heading-line">
									<span class="short-line bg-yellow-color"></span>
									<span class="long-line bg-yellow-color"></span>
								</div>
								<p class="heading-text c-white">Read what our client say about our SEO services, our client support & other things.</p>
							</div>
						</div>

						<div class="col-lg-7 col-lg-offset-1 col-md-8 col-sm-12 col-xs-12">

							<div class="testimonial-item">
								<div class="swiper-container testimonial__thumb overflow-visible" data-effect="fade" data-loop="false">

									<div class="swiper-wrapper">
										<div class="testimonial-slider-item swiper-slide">
											<div class="testimonial-content">
												<p class="text" data-swiper-parallax="-200" style={{backgroundColor: "#362f2d",
    borderRadius: "0 100px 0 100px",padding: "30px 70px",fontSize:"16px",color:"#fff"}}>Thank you for providing SEO services. My company was ranked first in Google Search and in Bahrain. In Bahrain, you may find printers, lasers, CNC machines, cutting plotters, machineries, and spare parts. Your SEO strategy worked for me; I'm pleased with the high-quality backlinks that were established, and I used all professional marketing techniques to get my website to the top of the Google search results. Expertise designed to establish a successful SEO strategy for my business. For my company, you've created a result-oriented SEO approach.
												</p>
												<a href="https://nabtaker.com/" target="_blank" class="author" data-swiper-parallax="-150">Ahmed Abdulaal</a>
												<a href="https://nabtaker.com/" target="_blank" class="company" data-swiper-parallax="-150">Founder (NABTAKER SIGN)</a>

											</div>
											<div class="avatar" data-swiper-parallax="-50">
												<img loading="lazy" src="assets/images/SeoServices/avatar.png" alt="avatar"/>
											</div>
										</div>
                                    </div>
									<div class="swiper-pagination"></div>

									<div class="quote">
										<i class="seoicon-quotes"></i>
									</div>
								</div>

								<div class="testimonial__thumb-img">
									<img loading="lazy" src="assets/images/SeoServices/testimonial1.png" alt="image"/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="testimonial-img">
					<img loading="lazy" src="assets/images/SeoServices/testimonial2.png" alt="image"/>
				</div>
			</div>
		</div>
	</div>
     </section>
     <section>
  <div className="container-fluid">
    <div className="row medium-padding120 bg-border-color">
      <div className="container">
        <div className="heading mb40 align-center">
          <span>FAQ</span>
          <h4 className="h4 heading-title mb-0">Frequently Asked Questions</h4>
          <div className="heading-line">
            <span className="short-line"></span>
            <span className="long-line"></span>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 md-mb-30">
            <img src="assets/images/SeoServices/digital-influencer-faq.webp" alt="faq" title="" className="img-fluid" />
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div id="accordion" className="crumina-module crumina-accordion">
              {/** Repeated code for accordion items */}
              {accordionData.map((item, index) => (
                <div className="card accordion-panel" key={index}>
                  <div className="card-header panel-heading" id={`heading${index}`}>
                    <button
                      className="btn-link accordion-heading"
                      data-toggle="collapse"
                      data-target={`#collapse${index}`}
                      aria-expanded={index === 0}
                      aria-controls={`collapse${index}`}
                    >
                      <span className="icon">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </span>
                      <span className="title">{item.question}</span>
                    </button>
                  </div>
                  <div
                    id={`collapse${index}`}
                    className={`panel-collapse collapse ${index === 0 ? "show" : ""}`}
                    aria-labelledby={`heading${index}`}
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <div className="panel-info">{item.answer}</div>
                    </div>
                  </div>
                </div>
              ))}
              {/** End of accordion items */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section style={{ backgroundColor: '#3CB371' }}>
  <div className="row">
    <div className="container">
      <div className="row justify-content-center">
        <div className="subscribe scrollme" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {/* Left images block */}
          <div className="images-block" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: '20px' }}>
            <img
              src="assets/images/Contact/subscr-gear.png"
              alt="gear"
              className="gear"
              style={{ marginBottom: '10px' }} // Space between images
            />
            <img
              src="assets/images/Contact/subscr1.png"
              alt="mail"
              className="mail"
            />
          </div>

          {/* Subscribe form block */}
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h4 className="subscribe-title" style={{ fontSize: "30px", color: "#fff", marginBottom: "23px", textAlign: "center" }}>
              Email Newsletters!
            </h4>
            <form className="subscribe-form crumina-submit" method="post">
              <input
                className="input-standard-grey input-white"
                name="email"
                placeholder="Your Email Address"
                type="email"
                required
                style={{
                  backgroundColor: "#fff",
                  padding: "16px 40px",
                  fontSize: "16px",
                  color: "#797676",
                  borderColor: "transparent",
                  borderRadius: "50px 0 0 50px",
                  width: "70%",
                  float: "left",
                }}
              />
              <button className="subscr-btn"
                style={{
                  display: "inline-block",
                  backgroundColor: "#2f2c2c",
                  padding: "17px 40px",
                  borderRadius: "0 50px 50px 0",
                  fontSize: "15px",
                  width: "30%",
                  cursor: "pointer",
                  position: "relative",
                  color: "#fff",
                  textAlign: "center",
                  padding: "19px 6px 22px",
                  fontSize: "13px",
                }}>
                subscribe
              </button>
			  <span class="semicircle"></span>
            </form>
            <div className="sub-title" style={{ color: "rgba(255, 255, 255, 0.5)", textAlign: "center", marginTop: "10px" }}>
              Sign up for new updates & offers.
            </div>
          </div>

          {/* Right image block */}
          <div className="images-block" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: '20px' }}>
            <img
              src="assets/images/Contact/subscr-mailopen.png"
              alt="mail"
              className="mail-2"
              style={{
                opacity: "1",
                right: "20px",
                bottom: "-50px"
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </>
  );
}
