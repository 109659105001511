import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import api from "../../../constants/api";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation
import childwoodzammy from "../../../../src/assets/images/childhood-zammy.webp";
import zammydubai from "../../../../src/assets/images/zammy-in-dubai.webp";
import zammy1 from "../../../../src/assets/images/zammy-1.webp";
import GooglerankingExpert from "../../../../src/assets/images/google-ranking-expert1.webp";
import artofattractingmoney from "../../../../src/assets/images/art-of-attracting-money.webp";
import zammysaifgoogle from "../../../../src/assets/images/zammy-zaif-google-ranking-expert.webp";
import zammybusinessmentor from "../../../../src/assets/images/zammy-business-mentor.webp";
import zammyPublicSpeaker from "../../../../src/assets/images/zammy-zaif-public-speaker.webp";
import zammyonline from "../../../../src/assets/images/zammy-online-presence.webp";
import seostratergy from "../../../../src/assets/images/seo-strategy.webp";

export default function About() {
  const [about, setAbout] = useState([]);
  const [testimonial, setTestimonials] = useState([]);
  const expertData = [
    { title: "Best SEO Expert in India", link: "/details/seo-expert" },
    { title: "Google Ranking Expert", link: "/details/google-ranking-expert" },
    { title: "GMB Ranking Specialist", link: "/details/gmb-specialist" },
    { title: "Internet Influencer", link: "/details/internet-influencer" },
    { title: "Google Influencer", link: "/details/google-influencer" },
    { title: "Best Local SEO Expert", link: "/details/local-seo-expert" },
    {
      title: "Google Ranking Specialist",
      link: "/details/google-ranking-specialist",
    },
    { title: "Google SEO Expert", link: "/details/google-seo-expert" },
  ];

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
    // Uncomment if you want to fetch dynamic content
    // getAboutContent();
  }, []);

  // Example function to get content (Uncomment if needed)
  // const getAboutContent = () => {
  //   api.get("/getAboutUs", { recordType: "Record" }).then((res) => {
  //     setAbout(res.data.data);
  //     api.post("/getContent", { recordType: "Testimonialpart" }).then((res) => {
  //       setTestimonials(res.data.data);
  //     });
  //   });
  // };

  return (
    <>
      <Helmet>
        <title>About Us</title>
      </Helmet>

      <section
        className="page-title page-title-overlay bg-cover overflow-hidden"
        style={{ backgroundImage: "url('/images/background/about.jpg')" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h1 className="text-white position-relative">
                About Us<span className="watermark-sm">About Us</span>
              </h1>
              <p className="text-white pt-4 pb-4">Zammy Saif</p>
            </div>
            <div className="col-lg-3 ml-auto align-self-end">
              <nav
                className="position-relative zindex-1"
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb justify-content-lg-end bg-transparent mb-4 px-0">
                  <li className="breadcrumb-item">
                    <a href="/" className="text-white">
                      Home
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white fw-bold"
                    aria-current="page"
                  >
                    About Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row">
            {/* Left Column for Content */}
            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 md-mb-30 text-m-center">
              <h3
                style={{
                  color: "#4cc2c0",
                  fontWeight: "600",
                  letterSpacing: "0.5px",
                  fontSize: "16px",
                }}
              >
                About Zammy Zaif
              </h3>
              <h2
                className="section-title1"
                style={{ fontFamily: "'Rubik', sans-serif" }}
              >
                Google Expert from India - Asia
              </h2>
              <div className="zammy-about">
                <p
                  style={{
                    fontFamily: "'Rubik', sans-serif",
                    fontSize: "15px",
                    letterSpacing: "0.5px",
                    lineHeight: "30px",
                  }}
                >
                  Zammy Zaif is a best Google SEO Expert from India residing in
                  Tirunelveli, Tamil Nadu who has had phenomenal success with
                  SEO, especially placing first on Google for a competitive term
                  like "Google Ranking Expert."
                  <br />
                  He achieved this world-class position in a short period of
                  time since he is a dedicated SEO specialist and also because
                  of his amazing hard work and smart work. When Google PageRank
                  first appeared on the scene, Zammy Zaif began his profession
                  in SEO around the same time. He rapidly understood that Search
                  Engine Optimization will play a significant role in his
                  professional future.
                  <br />
                  His huge achievement came only after experiencing a lot of
                  suffering and hardship. People used to call Zammy a "Computer
                  Genius" when he was a kid. He had about 15 cousins in his
                  house because he belonged to a joint family. When all of his
                  cousins just learned how to play games on the computer, little
                  Zammy performed more than that. He used to modify the computer
                  password that his uncle had set in order to prevent the
                  youngsters from using it.
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-m-center">
              <div class="zammy-sidebar mb-10">
                <h3 class="zammy-head mb-0">Zammy Zaif</h3>
                <ul class="zammy-list pl0">
                  <li>
                    <a href="best-seo-expert-india.php">
                      Best SEO Expert in India
                    </a>
                  </li>
                  <li>
                    <a href="google-ranking-expert.php">
                      Google Ranking Expert
                    </a>
                  </li>
                  <li>
                    <a href="google-my-business-expert.php">
                      GMB Ranking Specialist
                    </a>
                  </li>
                  <li>
                    <a href="internet-influencer.php">Internet Influencer</a>
                  </li>
                  <li>
                    <a href="google-influencer.php">Google Influencer</a>
                  </li>
                  <li>
                    <a href="best-local-seo-expert-india.php">
                      Best Local SEO Expert
                    </a>
                  </li>
                  <li>
                    <a href="google-ranking-specialist.php">
                      Google Ranking Specialist
                    </a>
                  </li>
                  <li>
                    <a href="google-seo-expert.php">Google SEO Expert</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
      <div class="container">
		<div class="row medium-padding120">

			<div class="col-lg-4 col-md-7 col-sm-12 col-xs-12 md-mb-30 text-m-center">
                <div class="zammy-brdr">
                    <div class="curv-img">
                        <img loading="lazy" src={childwoodzammy} alt="Zammy Zaif"/>
                    </div>
                </div>
			</div>

			<div class="col-lg-8 col-md-5 col-sm-12 col-xs-12 text-m-center">
				<div class="heading"> 
					<h3 class="h3 heading-title mb-0">Zammy Zaif in his Early Childhood</h3>
                    <div class="heading-line mb-10">
								<span class="short-line"></span>
								<span class="long-line"></span>
							</div>
				</div>
                <p class="mb-10 text-l-justify">He started using a computer at the age of nine and it was then that he aspired to become a computer engineer. Young Zammy reasoned that because a computer is an electronic machine, obtaining a Diploma in Electronics and Communication Engineering would help him reach his goal and he enrolled in a college for the same.</p>
                <p class="mb-10 text-l-justify">Later on, he discovered that he needed to major in Information Technology or Computer Science Engineering in order to get the position he had always aspired to. But it was too late for him to change his major and he had to drop out in the third year influenced by financial difficulties.</p>
                <p class="mb-0 text-l-justify">Following that, he enrolled in a three-month hardware and networking certification course. Over time, he developed into an expert in his area and he attracted consumers from all over the world. He used his hard-earned money to re-enrol in the same engineering course, which he finished successfully the following year with a different batch.</p>
			</div>

		</div>
	</div>

      </section>
      <section>
        <div class="container-fluid">
          <div class="row medium-padding120 bg-orange-color">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 md-mb-30 text-m-center">
                  <div class="heading">
                    <h3 class="h2 heading-title mb-0">
                      Overseas Life of Zammy
                    </h3>
                    <div class="heading-line mb-10">
                      <span class="short-line bg-yellow-color"></span>
                      <span class="long-line bg-yellow-color"></span>
                    </div>
                  </div>
                  <p
                    class="text-l-justify mb-10 c-white"
                    style={{ letterSpacing: "0.5px", lineHeight: "30px" }}
                  >
                    In the year 2008, Zammy, a fresher, launched Hopes Infosys
                    to provide hardware and networking services. Later, he
                    started blogging and learned the concepts of web design and
                    SEO. Due to his financial difficulties and family condition,
                    he began to work for other companies in foreign countries.
                    At first, he relocated to Dubai for a company and began his
                    overseas life there. Meanwhile, he learned more about search
                    engine optimization, blogging, content writing, email
                    marketing and internet marketing over time.
                  </p>
                  <p class="text-l-justify mb-0 c-white">
                    Following the contract from Dubai, he returned to India and
                    formally established his own company,{" "}
                    <b>
                      <a
                        class="zama c-white"
                        target="_blank"
                        href="https://bamstechnologies.org"
                      >
                        Bams Technologies
                      </a>
                    </b>
                    . With a handful of employees in Tirunelveli in January
                    2015, began to work for many companies and did several
                    independent projects in different countries, including web
                    designing, web development and SEO. In Oman, he assisted a
                    prominent firm in ranking #1 for the term "Hydraulic Spare -
                    OMAN," which is still the case. He completed multiple
                    projects in Myanmar and several other projects in the United
                    States, including Customer Relationship Management (CRM) and
                    Content Management System (CMS) software services.
                  </p>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-m-center">
                  <div class="media-brdr">
                    <img
                      loading="lazy"
                      src={zammydubai}
                      alt="Zammy Zaif"
                      title=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-fluid">
          <div class="row medium-padding120 bg-border-color">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 md-mb-30 text-m-center">
                  <img loading="lazy" src={zammy1} alt="Zammy Zaif" title="" />
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-m-center">
                  <div class="heading">
                    <span>Google Ranking Expert</span>
                    <h3 class="h2 heading-title mb-0">Corporate Entity</h3>
                  </div>
                  <p class="text-l-justify mb-10">
                    In 2016, as he was struggling due to some family issues, he
                    once again had to set about his overseas voyage and this
                    time he flew to Bahrain. In Bahrain, he designed a website
                    for an advertising firm that ranked first on Google in the
                    nation. In Qatar, he has also worked for firms such as
                    Milano Trading and Venture Partner. While overseas, he never
                    abandoned his own firm, Bams Technologies and worked on
                    various projects in Germany and the United Kingdom under the
                    name of his own organisation.
                  </p>
                  <p class="text-l-justify mb-10">
                    Regardless of the fact that his clients and other employers
                    thought of him as a successful and effective SEO expert, he
                    had always desired to establish his own corporate firm.
                  </p>
                  <p class="text-l-justify mb-0">
                    Zammy Zaif's company eventually struck gold in 2019. On May
                    1, Labour Day, he started{" "}
                    <a
                      class="zama"
                      target="_blank"
                      href="https://www.zaubacorp.com/company/BAMS-TECHNOLOGIES-PRIVATE-LIMITED/U72900TZ2019PTC032067"
                    >
                      Bams Technologies Private Limited
                    </a>{" "}
                    in Erode and Trivandrum alongside Suresh Kumar as a
                    co-founder.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-fluid">
          <div
            class="row medium-padding120"
            style={{ backgroundColor: "#F15B26" }}
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-8 col-md-7 col-sm-12 col-xs-12 md-mb-30 text-m-center">
                  <div class="heading">
                    <h3 class="h2 heading-title c-white mb-0">
                      Dream, Struggle, Perseverance
                    </h3>
                    <div class="heading-line mb-10">
                      <span class="short-line bg-yellow-color"></span>
                      <span class="long-line bg-yellow-color"></span>
                    </div>
                  </div>
                  <p class="text-l-justify mb-10 c-white">
                    BAMS stands for "Business Administration and Management
                    Services," respectively.
                  </p>
                  <p class="text-l-justify mb-10 c-white">
                    He founded this company to pursue his dream project, a "Next
                    Generation Social Media App," while also doing tasks such as
                    web designing, web development, app development, digital
                    marketing and other IT services. He spent almost 2 million
                    Indian rupees on his dream project and ultimately, the great
                    Bio War arrived in his life with the name Covid-19 as
                    Corona; the pandemic convulsed the globe and the firm was
                    forced to cease down. Zammy is a committed person who never
                    quits. He will stop at nothing to achieve a goal or a
                    purpose. His long-term goal of creating a next-generation
                    social media app is served by his short-term goal of
                    acquiring the sufficient amount of funds required for the
                    same. And for which, he is utilizing the SEO platform as an
                    economic resource.
                  </p>
                  <p class="text-l-justify mb-0 c-white" style={{paddingBottom:'20px'}}>
                    No one has ever achieved success in a single go, they must
                    have had many failed attempts before actually reaching their
                    goals. Zammy’s case is just the same. He is a true
                    inspiration for people who have failed in their lives.
                  </p>
                </div>
                <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12 text-m-center dbl-brdr">
                  <img
                    style={{ border: "3px solid #fff", borderRadius: "20px" }}
                    class="scale-1"
                    loading="lazy"
                    src={GooglerankingExpert}
                    alt="Zammy Zaif"
                    title=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-fluid">
          <div class="row medium-padding120 bg-boxed-black">
            <div class="container">
              <div class="row">
                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 md-mb-30 text-m-center">
                  <img
                    src={artofattractingmoney}
                    alt="art of attracting money"
                    title=""
                  />
                </div>

                <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 text-m-center">
                  <div class="heading">
                    <h3 class="h3 heading-title c-white mb-0">
                      Mind Map – A Success Prototype
                    </h3>
                    <div class="heading-line">
                      <span class="short-line"></span>
                      <span class="long-line"></span>
                    </div>
                  </div>
                  <p class="mb-10 text-l-justify c-white">
                    Even though he is a person who has achieved many things in
                    his life at a very young age, he considers himself as just
                    an entrepreneur who is in the initial stage of building
                    teams. His dream is to build a territory of his own where he
                    has complete authority, also he has to bring his business to
                    a supreme level in which it remains unaffected and continues
                    to run successfully even if he makes himself unavailable for
                    some duration of time.
                  </p>
                  <p class="mb-10 text-l-justify c-white">
                    He believes that a successful businessman is someone who has
                    achieved immense success also at the same time enormous
                    wealth through many years of expertise and extreme
                    passionate effort. He wants to become wealthy as well as a
                    powerful person in his particular business or industry. He
                    aims to attain more leads, revenue and reputation in the
                    long run and he knows exactly what goes into it. In order to
                    achieve this ultimate goal and be more successful in his
                    terms, Zammy has created a Mind Map that helps in analysing
                    himself to see where he stands in the journey of being a
                    successful businessman.
                  </p>
                  <p class="mb-0 text-l-justify c-white">
                    He has modelled this mind map as a{" "}
                    <b>"success prototype"</b> and observes the analysis process
                    as an <b>Art of Attracting Money</b> towards us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
    <div class="container">
        <div class="row medium-padding120 d-flex align-items-stretch">
            <div class="col-lg-6 col-sm-12 col-xs-12 md-mb-30 text-m-center d-flex flex-column">
                <div class="heading mb-10">
                    <h3 class="h3 heading-title mb-0">Zammy's Global Recognition</h3>
                    <div class="heading-line">
                        <span class="short-line"></span>
                        <span class="long-line"></span>
                    </div>
                </div>
                <div class="d-flex flex-grow-1 flex-column justify-content-between">
                    <p class="text-l-justify mb-10">
                        Since implementing this in his own life, he achieved the world level Google ranking for the keyword <b>"Google Ranking Expert"</b> and has rapidly made a brand name for himself. He has come a long way and gained many recognitions from all over the world. He is a key person in the Malaysia South India Chamber of Commerce and they designated him as the co-ordinator. He achieved a lifelong membership in a Canadian Community called <b>"Landed for Success"</b>. Zammy Zaif is the first and the only member from India who has attained this privilege.
                    </p>
                    <p class="text-l-justify mb-0">
                        Zammy finds this mind map very beneficial and has not only applied this strategy personally but has also helped many other people from small, mid-sized to large businessmen. He pitched his unique success mantra to all the ones who were failing in their businesses and has helped many businessmen who were on the verge of closing down.
                    </p>
                </div>
            </div>
            
        
            <div class="col-lg-6 col-sm-12 col-xs-12 text-center d-flex align-items-center justify-content-center">
                <img loading="lazy" src={zammysaifgoogle} alt="Google Ranking Expert" title=""/>
            </div>
        </div>
    </div>
</section>
<section>

    <div class="container-fluid">
        <div class="row medium-padding120 bg-border-color d-flex align-items-stretch">
            <div class="container">
                <div class="row d-flex align-items-stretch">
                 
                    <div class="col-lg-6 col-sm-12 col-xs-12 md-mb-30 text-m-center d-flex justify-content-center align-items-center">
                        <img loading="lazy" class="br-5" src={zammybusinessmentor} alt="Zammy, Business Mentor" title=""/>
                    </div>
                    
                    <div class="col-lg-6 col-sm-12 col-xs-12 text-m-center d-flex flex-column justify-content-between">
                        <div class="heading">
                            <h3 class="h3 heading-title mb-0">Zammy - Business Mentor</h3>
                            <div class="heading-line">
                                <span class="short-line"></span>
                                <span class="long-line"></span>
                            </div>
                        </div>
                        <div>
                            <p class="text-l-justify mb-10">He has also been a mentor to entrepreneurs who had just started a company and later found that things weren't working out the way they thought it would be. He has been a great help in empowering several businesspersons who were in business for more than 20 years and still seemed struggling. Many business tycoons who were already in a good position in their industry, hoping to take it to the next level had approached Zammy to gain valuable advice from him.</p>
                            <p class="text-l-justify mb-0">When he first started hosting webinars, a minimum of 70 people would show up for each session. Following that, when he began professionally delivering sessions twice a month, exclusively for businesspeople, a minimum of 15 individuals showed up for each session.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
<div class="container-fluid">
		<div class="row bg-blue medium-padding120">

			<div class="container">
				<div class="row">
			<div class="col-lg-6 col-sm-12 col-xs-12 md-mb-30 text-m-center">
				<div class="heading">
					<h3 class="h3 heading-title mb-0 c-white">A Great Public Speaker</h3>
					<div class="heading-line">
								<span class="short-line bg-yellow-color"></span>
								<span class="long-line bg-yellow-color"></span>
							</div>
				</div>
                <div class="">
                    <p class="text-l-justify mb-10 c-white">His workshops are mainly based on topics “Money Making, Business Growth, Secret of Success and The Art of Attracting Money.” Whether if you are a person who is taking baby steps in your business or a person who is planning to take a big leap in your business, Zammy can guide you through, to reach great success. You can have a look at the testimonial videos from the benefitted businesspersons on his Instagram, go search him: googlerankingexpert.</p>
                    <p class="text-l-justify mb-0 c-white" style={{paddingBottom:'20px'}}>He has also been taking SEO lessons that he learned along the way. Many enthusiasts who seek opportunities and aspirants who wish to start a career in SEO have joined the course to learn from Zammyand they all call him “Master in SEO.”</p>
                </div>
			</div>
                    
                    <div class="col-lg-6 col-sm-12 col-xs-12 text-m-center  dbl-brdr">
				<img 
        style={{border:'3px solid #fff', borderRadius:'20px'}}
        loading="lazy" 
        src={zammyPublicSpeaker} 
        alt="A Great Public Speaker" 
        title=""/>
			</div>
                    
                    
				</div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container-fluid">
        <div class="row medium-padding120 bg-border-color d-flex align-items-stretch">
            <div class="container">
                <div class="row d-flex align-items-stretch">
                    <div class="col-lg-5 col-sm-12 col-xs-12 md-mb-30 text-m-center d-flex justify-content-center align-items-center">
                        <img class="choose-brand" loading="lazy" src={zammyonline} alt="Zammy's Online Presence" title=""/>
                    </div>
                    
                    <div class="col-lg-7 col-sm-12 col-xs-12 text-m-center d-flex flex-column justify-content-between">
                        <div class="heading">
                            <h3 class="h3 heading-title mb-0">Zammy's Online Presence</h3>
                            <div class="heading-line mb-10">
                                <span class="short-line"></span>
                                <span class="long-line"></span>
                            </div>
                        </div>
                        <div>
                            <p class="text-l-justify mb-10">
                                Zammy has always tried to impart his knowledge to people in different ways and he has always been such a great public speaker. For this, he makes use of many digital platforms to reach a large audience. He has made his online presence felt in MSN Messenger, Yahoo Messenger, Tamil Flash Chatrooms, Nimbus Chat, Mig33 Chat, Qeep Chat (he has been a qeeper for nearly 8 years), Bigo Live (he has nearly 10k followers), MICO and Hago, from its very beginning itself.
                            </p>
                            <p class="text-l-justify mb-0">
                                He is also a Telegram Dominator with 40+ job groups each with 40,000 members, where he spends his own money to disseminate information about job openings. He has also made it into the clubhouse, where he's the administrator of two clubs: "Tamil Global Business," which has 5,000 members and "Tamil Business Coaching," which has 1000 members. He is also the leader of "Vere Level Business" (VLB) group, which has 12,500 members. He has a tremendous fan base in clubhouse, with 1.5k followers and anytime he gives a talk there, at least 500 people show up to listen to him.
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</section>

<section>
  
	<div class="container">
		<div class="row medium-padding120">
			<div class="row">
      <div class="col-lg-7 col-sm-12 col-xs-12 text-m-center d-flex flex-column justify-content-between">
      <div class="heading">
					<h2 class="h3 heading-title mb-0">SEO Strategy of Zammy Zaif</h2>
					<div class="heading-line mb-10">
						<span class="short-line"></span>
						<span class="long-line"></span>
					</div>
				</div>
                <div class="">
                    <p class="text-l-justify mb-0">Without having very deep pockets and spending a lot of money, every firm can increase its sales and profits. Zammy demonstrates how to leverage SEO to enhance organic traffic, lead generation and conversion. He is the most trusted Google Ranking SEO Expert as recognised by Google itself who is capable of ranking your business on the first page first rank of Google search results. He is a devoted SEO professional with more than a decade of vast knowledge in this sector.</p>
                    <p class="text-l-justify mb-0">He begins by learning about his clients’ targeted audience, potential customers, competitors and most importantly, their business culture. He then creates a customised SEO strategy that focuses on meeting his clients' needs in order to generate income for them. His unique approach puts them miles ahead of the competition, allowing them to establish trust and credibility among the public while also establishing their company as a leader in that particular field.</p>
                    <p class="text-l-justify mb-0">Zammy Zaif keeps a keen eye on every little detail and tiny aspect of search engine optimization with his extensive learning expertise. He is always up to date with the latest Google ranking factors and algorithms. He will go to any extent to achieve the desired organic result. Zammy Zaif is unquestionably a man of action, as evidenced by the end outcome. So Everyone expects that in this 2022 Zammy will be the world's leading seo Expert.</p>
                </div>
			</div>
      <div class="col-lg-5 col-sm-12 col-xs-12 md-mb-30 text-m-center d-flex justify-content-center align-items-center">
      <img class="br-5" loading="lazy" src={seostratergy} alt="seo concept" title=""/>
			</div>
                    
				</div>
		</div>
	</div>
</section>
    </>
  );
}
