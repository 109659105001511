import React,{useState} from 'react'
import api from '../constants/api';
import AOS from 'aos';

export default function Footer() {
  const [menus, setMenus] = useState([])
  const [email, setEmail] = useState();
  const [companyname, setCompanyName] = useState();
  const [address, setAddress] = useState();
  const [contact, setContact] = useState();

  React.useEffect(() => {
    AOS.init();
    getBlogs();
    getAddress();
    getCompanyName();
    getMobile();
    getEmail();
    window.scrollTo(0,0)
   
  }, [])



  const getBlogs = () =>{
    api.post('/getMenu').then(res=>{
     
      let loopData = res.data.data
      var result = loopData.reduce(function (r, a) {
          r[a.section_title] = r[a.section_title] || [];
          r[a.section_title].push(a);
          return r;
      }, Object.create(null));
     
      let menuArray = [];
      Object.keys(result).forEach(function(key, index) {
        menuArray.push({section_title:key,value:result[key]})
      });
      setMenus(menuArray)
      //console.log(menuArray)
     
    })
    
  }

  const getEmail = () =>{
    api.get('/getEmail').then(res=>{
      setEmail(res.data.data[0])
     })
  }
  
  const getCompanyName= () =>{
    api.get('/getCompanyName').then(res=>{
      setCompanyName(res.data.data[0])
     })
  }
  
  const getAddress = () =>{
    api.get('/getAddress').then(res=>{
      setAddress(res.data.data[0])
     })
  }
  
  const getMobile = () =>{
    api.get('/getContacts').then(res=>{
      setContact(res.data.data[0])
     })
  }
  return (
    <>
    <footer className="bg-secondary">
    <section className="section border-bottom border-color py-5">
    <div className="container">
        <div className="row">
            <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <div className="heading mb-4">
                    <h4 className="h4 heading-title" style={{ marginTop: "20px" }}>About Zammy!</h4>
                    <div className="heading-line mb-2">
                        <span className="short-line"></span>
                        <span className="long-line"></span>
                    </div>
                    <p className="heading-text" style={{ color: "#fff" }}>
              Zammy Zaif, featured by Google as a Google Ranking Expert Globally, is dedicated to helping every client achieve first rank, with a focus that extends beyond traditional first-page SEO services.
                    </p>
                </div>

                <div className="socials mb-3">
                    <a href="https://goo.gl/maps/ihFiQeqtGGQfrkqh7" target="_blank" rel="noopener noreferrer" className="social__item mx-1">
                        <img loading="lazy" src="assets/images/Footer/google.webp" alt="Google" title="Google"/>
                    </a>
                    <a href="https://www.facebook.com/googleseospecialist" target="_blank" rel="noopener noreferrer" className="social__item mx-1">
                        <img loading="lazy" src="assets/images/Footer/fb.webp" alt="Facebook"/>
                    </a>
                    <a href="https://twitter.com/gseospecialist" target="_blank" rel="noopener noreferrer" className="social__item mx-1">
                        <img loading="lazy" src="assets/images/Footer/twitter.webp" alt="Twitter"/>
                    </a>
                    <a href="https://www.instagram.com/googlerankingexpert/" target="_blank" rel="noopener noreferrer" className="social__item mx-1">
                        <img loading="lazy" src="assets/images/Footer/instagram.webp" alt="Instagram"/>
                    </a>
                    <a href="https://www.linkedin.com/in/googlerankingexpert/" target="_blank" rel="noopener noreferrer" className="social__item mx-1">
                        <img loading="lazy" src="assets/images/Footer/linkedin.webp" alt="LinkedIn"/>
                    </a>
                </div>
            </div>

            <div className="col-lg-4 col-lg-offset-1 col-md-4 col-md-offset-1 col-sm-12 col-xs-12">
                <div className="services">
                    <div className="heading mb-4">
                        <h4 className="heading-title" style={{ marginTop: "20px",textAlign:"center" }}>Quick Links</h4>
                        <div className="heading-line mb-2" style={{textAlign:"center"}}>
                            <span className="short-line"></span>
                            <span className="long-line"></span>
                        </div>
                    </div>

                    <ul style={{ display: "flex", justifyContent: "center", listStyleType: "none", padding: 0 }}>
                        {[
                            { href: "https://it2.tv/google-ranking-expert.php", text: "Google Ranking Expert" },
                            { href: "https://it2.tv/google-my-business-expert.php", text: "GMB Ranking Specialist" },
                            { href: "https://it2.tv/best-seo-expert-india.php", text: "Best SEO Expert" },
                            { href: "https://it2.tv/internet-influencer.php", text: "Internet Influencer" },
                            { href: "https://it2.tv/google-influencer.php", text: "Google Influencer" },
                            { href: "best-local-seo-expert-india.php", text: "Best Local SEO Expert" },
                            { href: "https://it2.tv/seo-update/gseo-certificate-verification/", text: "Course Verification" },
                            { href: "https://it2.tv/academy/ai-based-google-seo-course-tamil.php", text: "Google SEO Training" },
                            { href: "#", text: "Privacy Policy" },
                            { href: "#", text: "Cookie Policy" },
                            { href: "https://it2.tv/contact-zammy-zaif.php", text: "Contact Us" },
                        ].map(link => (
                          <li key={link.text} >
                          {/* <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                          <a href={link.href} style={{ color: "#fff",textAlign:"center" }}>{link.text}</a>
                      </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>


        <div className="row mt-4">
            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-3">
                <div className="contacts-item">
                    <div className="content">
                        <a href="tel:+919344618144" className="title" style={{color:"#fff"}}>+91-9344 618 144</a>
                        <p className="sub-title " style={{color:"#fff"}}>Mon-Fri 10am-6pm</p>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-3">
                <div className="contacts-item">
                    <div className="content">
                        <a href="mailto:zammy@google.com" className="title" style={{color:"#fff"}}>zammy@google.com</a>
                        <p className="sub-title" style={{color:"#fff"}}>Email Us</p>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-3">
                <div className="contacts-item">
                    <div className="content">
                        <a href="https://goo.gl/maps/ihFiQeqtGGQfrkqh7" target="_blank" rel="noopener noreferrer" className="title" style={{color:"#fff"}}>Chennai, India</a>
                        <p className="sub-title "style={{color:"#fff"}}>Visit Us</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="footer-bottom text-center mt-4">
            <div className="copy-right">© 2024 Zammy. All rights reserved.</div>
        </div>
    </div>
    </section>
</footer>


    </>
  )
}
