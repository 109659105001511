import React, { useState ,useEffect} from "react";
import ReCAPTCHA from "react-google-recaptcha";


export default function Contact() {
	const [pageUrl, setPageUrl] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted");
  };

 
  useEffect(() => {
    // Set the current page URL
    setPageUrl(window.location.href);

    // Load the reCAPTCHA script
    const loadReCaptcha = () => {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=6LcsApkhAAAAAMYgkxg_Du9jRbCXlHuJbCanu8FU`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    loadReCaptcha();
  }, []);

  const handleRecaptcha = (value) => {
    console.log("Captcha value:", value);
    // You can handle the reCAPTCHA value here (e.g., send it to your server)
  };
  


  return (
    <>
    

	<section
        class="page-title page-title-overlay bg-cover overflow-hidden"
        data-background="#11476a"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <h1 class="text-white position-relative">
                Contact<span class="watermark-sm">Contact</span>
              </h1>
              <p class="text-white pt-4 pb-4">We are happy to assist you!!</p>
            </div>
            <div class="col-lg-3 ml-auto align-self-end">
              <nav class="position-relative zindex-1" aria-label="breadcrumb">
                <ol class="breadcrumb justify-content-lg-end bg-transparent mb-4 px-0">
                  <li class="breadcrumb-item">
                    <a href="index.html" class="text-white">
                      Home
                    </a>
                  </li>
                  <li
                    class="breadcrumb-item text-white fw-bold"
                    aria-current="page"
                  >
                    Contact
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

	  <section>    
  <div className="container">
    <div className="row">
      <div className="col-lg-12 text-center"> 
        <h2 className="section-title"
          style={{
            fontSize: "38px",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          Get In Touch
        </h2>
        
        <div className="heading-line" style={{ marginBottom: "20px" }}>
          {/* Short Line with animation */}
          <span className="short-line"
            style={{
              display: 'inline-block',
              width: "100px", // Final width
              height: "3px",
              backgroundColor: "#4cc2c0",
              borderRadius: "10px",
              marginRight: "10px",
              animation: 'move 1s infinite alternate', // Apply moving animation
            }}
          ></span>

          {/* Long Line with animation */}
          <span className="long-line"
            style={{
              display: 'inline-block',
              width: "30px", // Final width
              height: "3px",
              backgroundColor: "#4cc2c0",
              borderRadius: "10px",
              animation: 'move 1s infinite alternate 0.3s', // Apply moving animation with delay
            }}
          ></span>
        </div>

        <div className="col-lg-12 text-center">
          <div className="container center" style={{ maxWidth: "800px", margin: "auto" }}>
            <p className="heading-text"
              style={{
                fontSize: "24px",
                color: "#000",
                fontWeight: "500",
                letterSpacing: "1px",
              }}
            >
              For any inquiries or collaborations, please fill out the form below or email us at info@bamstechnologies.org. Let's create something amazing together!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style jsx>{`
  @keyframes move {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(10px); /* Adjust to the distance you want the line to move */
    }
  }
`}</style>

	  
	   <section className="section">    
  <div className="row"> 
    <div className="col-lg-4 d-flex align-items-stretch"> {/* Ensuring equal height */}
      <div className="p-5 rounded-xs shadow w-100 text-center"> {/* Text-center for centering text */}
        <ul className="list-unstyled">
          <li className="d-flex flex-column justify-content-center align-items-center mb-4" data-aos="fade-up" data-aos-delay="100">
            <img
              src="assets/images/Contact/contact7.png"
              alt="Address"
              style={{
                maxWidth: "40%",  /* Optional: Adjust the image width */
                height: "auto",
                marginBottom: "15px"
              }}
            />
            <h6
              className="text-dark"
              style={{
                fontSize: "20px",
                color: "#000",
                fontWeight: "500",
                marginTop: "0px",
                letterSpacing: "1px",
                textAlign: "center"
              }}
            >
              Tirunelveli | Erode | Trivandrum
            </h6>
          </li>
        </ul>
      </div>
    </div>

    <div className="col-lg-4 d-flex align-items-stretch"> {/* Ensuring equal height */}
      <div className="p-5 rounded-xs shadow w-100 text-center">
        <ul className="list-unstyled">
          <li className="d-flex flex-column justify-content-center align-items-center mb-4" data-aos="fade-up" data-aos-delay="100">
            <img
              src="assets/images/Contact/contact8.png"
              alt="Email"
              style={{
                maxWidth: "40%",  /* Optional: Adjust the image width */
                height: "auto",
                marginBottom: "15px"
              }}
            />
            <a href="mailto:info@bamstechnologies.org">
              <h6
                className="text-dark"
                style={{
                  fontSize: "20px",
                  color: "#000",
                  fontWeight: "500",
                  marginTop: "0px",
                  letterSpacing: "1px",
                  textAlign: "center"
                }}
              >
                info@bamstechnologies.org
              </h6>
            </a>
            <ul className="list-unstyled">
              <li>Online support</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <div className="col-lg-4 d-flex align-items-stretch"> {/* Ensuring equal height */}
      <div className="p-5 rounded-xs shadow w-100 text-center">
        <ul className="list-unstyled">
          <li className="d-flex flex-column justify-content-center align-items-center mb-4" data-aos="fade-up" data-aos-delay="100">
            <img
              src="assets/images/Contact/contact9.png"
              alt="Phone"
              style={{
                maxWidth: "40%",  /* Optional: Adjust the image width */
                height: "auto",
                marginBottom: "15px"
              }}
            />
            <h6
              className="text-dark"
              style={{
                fontSize: "20px",
                color: "#000",
                fontWeight: "500",
                marginTop: "0px",
                letterSpacing: "1px",
                textAlign: "center"
              }}
            >
              +91 9344 618 144
            </h6>
            <ul className="list-unstyled">
              <li>Mon-Sat 10am-6pm</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<section >    
<div >
		<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3943.5988901164956!2d77.6881652!3d8.7295832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b04116b74fb527d%3A0xede392a219af9177!2sZammy%20Zaif%20%7C%20Google%20Ranking%20SEO%20Expert%20%7C%20GMB%20Specialist%20-%20India!5e0!3m2!1sen!2sin!4v1669439277555!5m2!1sen!2sin" width="100%" height="510" loading="lazy"></iframe>
	</div>
</section>
<section style={{ paddingTop: 10 }} className="section">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="subtitle">Have You Any Questions?</h1>
            <h2 className="section-title">HOW CAN WE HELP YOU</h2>
          </div>
          <div className="col-12 text-center">
            <form action="#" className="row">
              <div className="col-lg-4">
                <input type="text" className="bg-light form-control mb-4" placeholder="Your Name*" name="name" />
              </div>
              <div className="col-lg-4">
                <input type="text" className="bg-light form-control mb-4" placeholder="Website URL*" name="url" />
              </div>
              <div className="col-lg-4">
                <input type="text" className="bg-light form-control mb-4" placeholder="Email Address" name="email" />
              </div>
              <div className="col-lg-4">
                <input type="number" className="bg-light form-control mb-4" placeholder="Phone*" name="phone" />
              </div>
              <div className="col-lg-12">
                <textarea className="bg-light form-control mb-4" placeholder="Details" name="message"></textarea>
              </div>
              <input type="hidden" name="page_url" value={pageUrl} />
              <div className="row justify-content-center" style={{ marginBottom: '20px', display: 'flex' }}>
                <div className="g-recaptcha" data-sitekey="6LcsApkhAAAAAMYgkxg_Du9jRbCXlHuJbCanu8FU"></div>
              </div>
              <div className="col-12">
              
        <button type="button" className="btn btn-primary">
        <span class="semicircle"></span>
                Submit Now
              </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>


<section style={{ backgroundColor: '#3CB371' }}>
  <div className="row">
    <div className="container">
      <div className="row justify-content-center">
        <div className="subscribe scrollme" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {/* Left images block */}
          <div className="images-block" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: '20px' }}>
            <img
              src="assets/images/Contact/subscr-gear.png"
              alt="gear"
              className="gear"
              style={{ marginBottom: '10px' }} // Space between images
            />
            <img
              src="assets/images/Contact/subscr1.png"
              alt="mail"
              className="mail"
            />
          </div>

          {/* Subscribe form block */}
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h4 className="subscribe-title" style={{ fontSize: "30px", color: "#fff", marginBottom: "23px", textAlign: "center" }}>
              Email Newsletters!
            </h4>
            <form className="subscribe-form crumina-submit" method="post">
              <input
                className="input-standard-grey input-white"
                name="email"
                placeholder="Your Email Address"
                type="email"
                required
                style={{
                  backgroundColor: "#fff",
                  padding: "16px 40px",
                  fontSize: "16px",
                  color: "#797676",
                  borderColor: "transparent",
                  borderRadius: "50px 0 0 50px",
                  width: "70%",
                  float: "left",
                }}
              />
              <button className="subscr-btn"
                style={{
                  display: "inline-block",
                  backgroundColor: "#2f2c2c",
                  padding: "17px 40px",
                  borderRadius: "0 50px 50px 0",
                  fontSize: "15px",
                  width: "30%",
                  cursor: "pointer",
                  position: "relative",
                  color: "#fff",
                  textAlign: "center",
                  padding: "19px 6px 22px",
                  fontSize: "13px",
                }}>
                subscribe
                {/* <span class="semicircle"></span> */}
              </button>
            </form>
            <div className="sub-title" style={{ color: "rgba(255, 255, 255, 0.5)", textAlign: "center", marginTop: "10px" }}>
              Sign up for new updates & offers.
            </div>
          </div>

          {/* Right image block */}
          <div className="images-block" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: '20px' }}>
            <img
              src="assets/images/Contact/subscr-mailopen.png"
              alt="mail"
              className="mail-2"
              style={{
                opacity: "1",
                right: "20px",
                bottom: "-50px"
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  );
}
