import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import Chart from 'react-apexcharts';
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import { Checkbox } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";
import api from "../../../constants/api";
import { Button, Col, Form, Input, Row, message } from "antd";
// //import moment from 'moment';
import ReactHtmlParser from "react-html-parser";
import { Link, useNavigate } from "react-router-dom";
import imageBase from "../../../constants/image.js";
import { Helmet } from "react-helmet";
import seoexpert from "../../../../src/assets/images/google-ranking-expert.webp";
import seoexpert1 from "../../../../src/assets/images/serp-preview.jpg";
import seoexpert2 from "../../../../src/assets/images/zammy-zaif.png";
import seoexpert3 from "../../../../src/assets/images/google-ranking-expert-analyse-search-engine.jpg";
import result from "../../../../src/assets/images/result.webp";
import optimize from "../../../../src/assets/images/optimize.webp";
import search from "../../../../src/assets/images/search.webp";
import contactimage from "../../../../src/assets/images/contact.webp";
import seoexpert5 from "../../../../src/assets/images/website-mobile-usability.png";
import seoexpert6 from "../../../../src/assets/images/eat-principle.jpg";
import seoexpert7 from "../../../../src/assets/images/google-bot-ai.png";
import seoexpert8 from "../../../../src/assets/images/zammy-team-working-for-rank-1-seo.png";
import seoexpert9 from "../../../../src/assets/images/google-branding-specialist.webp";
import seoexpert10 from "../../../../src/assets/images/digital-influencer-faq.webp";
import seoexpert11 from "../../../../src/assets/images/google-ranking-expert.gif";
import slider1 from "../../../../src/assets/images/slider1.jpg";
import ambicaGoldBuyer from "../../../../src/assets/images/ambica-gold-buyer-1st-rank-result(Release Pledged Gold in Bommanahalli).webp";
import bakenjoy from "../../../../src/assets/images/bake-n-joy-1st-rank-result(bakery raw material in trivandrum).webp";
import dentruOralDentalWellnessClinic from "../../../../src/assets/images/dentru oral dental wellness clinic-1st-rank-result(Dental surgeon in new gurgaon).webp";
import expertProCleaning from "../../../../src/assets/images/expert-pro-cleaning Ltd-llc-1st-rank-result(cleaning experts in kamloops).webp";
import gbsSystemandService from "../../../../src/assets/images/gbs-system and service-1st-rank-result(Computer repair services in thoraipakkam).webp";
import infandTrader from "../../../../src/assets/images/infant-traders-1st-rank-result (scuba products in tuticorin).webp";
import kathleenBlackInternationalInc from "../../../../src/assets/images/kathleen-black International Inc-1st-rank-result(real estate business coach in toronto).webp";
import RMahal from "../../../../src/assets/images/RK Mahal.png";
import mulberryDentalClinic from "../../../../src/assets/images/mulberry Dental Clinic-1st-rank-result(pediatric Orthodontist in Nallagandla).webp";
import orangebeautyclinic from "../../../../src/assets/images/orange beauty-clinic-1st-rank-result(Scar removal clinic in valasaravakkam).webp";
import primaProperties from "../../../../src/assets/images/prima-properties-1st-rank-result (Property buying company london).webp";
import responseBoilerRepair from "../../../../src/assets/images/response-boiler repair-2nd-rank-result(boiler contractor in liverpool).webp";
import sindhuram from "../../../../src/assets/images/sindhuram-3rd-rank-result(speed reading coach in chennai).webp";
import sportFitPhysioCare from "../../../../src/assets/images/sport-fit- Physio Care1st-rank-result(Chiropractor in nungambakkam).webp";
import sriSairamTuitionCenter from "../../../../src/assets/images/sri-sairam-tuition- center1st-rank-result(Physics tuition center in velachery).webp";
import studio7rkPhotography from "../../../../src/assets/images/studio7rk Photography-1st-rank-result(Baby shoot Photographers in salem).webp";
import womansPlaza from "../../../../src/assets/images/womans-plaza-1st-rank-result(Latest Lehenga designs in Agra).webp";
import zainTowingLLC from "../../../../src/assets/images/zain-towing LLC-1st-rank-result( Towing service in kenner).webp";

//rank -1
import GarudaToursTravels from "../../../../src/assets/images/Garuda Tours & Travels (Vellore tirupati packages)-seo-service.webp";
import NabtakerSign from "../../../../src/assets/images/Nabtaker Sign  (Dx5 Bahrain)-google-seo-service.webp";
import NewhausRoofing from "../../../../src/assets/images/Newhaus  Roofing (Flat roofing contractor los angeles) -los-angeles-seo-snippet-result.webp";
import PrimaProperties from "../../../../src/assets/images/Prima Properties (Sell house fast london)-google-seo-service.webp";
import ProgressiveAcServices from "../../../../src/assets/images/Progressive Ac Services (Hvac contractor north hollywood)newhaus-los-angeles-seo-service.webp";
import ShreeshaBuilding from "../../../../src/assets/images/Shreesha Building solution-mobile foam concrete.webp";
import ZainTowing from "../../../../src/assets/images/Zain Towing LLC(towing service in kenner)   lousiana-seo-service.webp";
import SkinInsightCosmetic from "../../../../src/assets/images/Skin Insight Cosmetic (Cuddalore pigmentation treatment centre) cuddalore-seo-service.webp";
import KansasOverseasCareers from "../../../../src/assets/images/Kansas Overseas Careers( Australia immigration consultants)-seo-rank-service.webp";




export default function Home() {
  const [blogs, setBlogs] = useState([]);
  const [testimonial, setTestimonials] = useState([]);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [choose, setWhychooseus] = useState([]);

  const optionsdoughnut = {
    series: [48, 23, 17, 22], // Example data
    options: {
      chart: {
        type: 'donut',
      },
      labels: ['SEO', 'GMB Ranking', 'Website Clicks', 'ROI'], // Example labels
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.globals.series[opts.seriesIndex]; // Show only raw value, no percentage
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val; // Show raw value in the tooltip
          },
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false, // Disable total in the center
            },
          },
        },
      },
    },
  };
  
  const staticImagesRankOne = [
    { image: GarudaToursTravels, heading: 'Garuda Tours & Travels', des:'Vellore tirupati packages',link:'' },
    { image: NabtakerSign, heading: 'Newhaus  Roofing', des:'Dx5 Bahrain',link:'' },
    { image: NewhausRoofing, heading: 'Newhaus  Roofing', des:'Flat roofing contractor los angeles',link:''  },
    { image: PrimaProperties, heading: 'Prima Properties', des:'Sell house fast london',link:''  },
    { image: ProgressiveAcServices, heading: 'Progressive Ac Services', des:'Hvac contractor north hollywood' ,link:'' },
    { image: ShreeshaBuilding, heading: 'Shreesha Building solution', des:'mobile foam concrete',link:''  },
    { image: ZainTowing, heading: 'Zain Towing LLC', des:'towing service in kenner',link:''  },
    { image: SkinInsightCosmetic, heading: 'Skin Insight Cosmetic', des:'Skin Insight Cosmetic',link:''  },
    { image: KansasOverseasCareers, heading: 'Kansas Overseas Careers', des:' Australia immigration consultants' ,link:'' },
  ];

  const staticImages = [
    { image: RMahal, heading: 'RK Mahal', des:'AC Auditorium in chennai',link:'' },
    { image: ambicaGoldBuyer, heading: 'Ambica Gold Buyer', des:'Release Pledged Gold in Bommanahalli',link:'' },
    { image: bakenjoy, heading: 'Bake N Enjoy', des:'bakery raw material in trivandrum',link:''  },
    { image: dentruOralDentalWellnessClinic, heading: 'Dentru Oral Dental Wellness Clinic', des:'Dental surgeon in new gurgaon',link:''  },
    { image: expertProCleaning, heading: 'Expert Pro Cleaning', des:'cleaning experts in kamloops' ,link:'' },
    { image: gbsSystemandService, heading: 'GBS System and Service', des:'Computer repair services in thoraipakkam',link:''  },
    { image: infandTrader, heading: 'Infand Trader', des:'scuba products in tuticorin',link:''  },
    { image: kathleenBlackInternationalInc, heading: 'Kathleen Black International Inc', des:'real estate business coach in toronto',link:''  },
    { image: mulberryDentalClinic, heading: 'Mulberry Dental Clinic', des:'pediatric Orthodontist in Nallagandla' ,link:'' },
    { image: orangebeautyclinic, heading: 'Orange Beauty Clinic', des:'Scar removal clinic in valasaravakkam',link:''  },
    { image: primaProperties, heading: 'Prima Properties', des:'Property buying company london',link:'https://www.google.com/maps/search/property+buying+company+london/@51.489317,-0.1018846,10z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MDkyMi4wIKXMDSoASAFQAw%3D%3D' },
    { image: responseBoilerRepair, heading: 'Response Boiler Repair', des:'boiler contractor in liverpool',link:''  },
    { image: sindhuram, heading: 'Sindhu Ram', des:' speed reading coach in chennai' ,link:'' },
    { image: sportFitPhysioCare, heading: 'SportFit PhysioCare' , des:'Chiropractor in nungambakkam',link:'' },
    { image: sriSairamTuitionCenter, heading: 'Sri Sairam Tuition Center', des:'Physics tuition center in velachery',link:''  },
    { image: studio7rkPhotography, heading: 'Studio 7RK Photography', des:'Baby shoot Photographers in salem',link:''  },
    { image: womansPlaza, heading: 'Woman\'s Plaza', des:'Latest Lehenga designs in Agra',link:''  },
    { image: zainTowingLLC, heading: 'Zain Towing LLC' , des:'Towing service in kenner',link:'https://www.google.com/search?q=Towing+and+Recovery+Service+in+kenner'  }
  ];

 
  
  
  const navigate = useNavigate();

  const [contact, setContact] = React.useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Array of static images
  
 
    // Define your questions and answers
    const faqData = [
      {
        question: "What is SEO?",
        answer: "SEO stands for Search Engine Optimization. It is the process of optimizing your website to rank higher in search engine results."
      },
      {
        question: "How does SEO work?",
        answer: "SEO works by optimizing your website’s content, structure, and technical aspects to be more attractive to search engines, increasing your chances of ranking higher."
      },
      {
        question: "How long does it take to see results from SEO?",
        answer: "SEO is a long-term strategy. It may take a few months to start seeing significant results depending on competition and the effectiveness of your efforts."
      },
      {
        question: "Do I need to hire an SEO expert?",
        answer: "Hiring an SEO expert can help you achieve faster and more effective results, but it's possible to learn and apply basic SEO techniques yourself."
      },
    ];
  
    // State to keep track of which question is expanded
    const [activeIndex, setActiveIndex] = useState(null);
  
    // Function to toggle active question
    const toggleAnswer = (index) => {
      if (activeIndex === index) {
        setActiveIndex(null); // Close if already open
      } else {
        setActiveIndex(index); // Open selected question
      }
    };
  const [product, setProduct] = useState([]);
  const [service, setService] = useState([]);

  const handleProductChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setProduct([...product, value]);
    }

    // Case 2  : The user unchecks the box
    else {
      setProduct(product.filter((e) => e !== value));
    }
  };
  const applyChanges = () => {};

  const handleServiceChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setService([...service, value]);
    }

    // Case 2  : The user unchecks the box
    else {
      setService(service.filter((e) => e !== value));
    }
  };
  const updateContactFields = (e) => {
    const fieldName = e.target.name;
    setContact((existingValues) => ({
      // Retain the existing values
      ...existingValues,
      // update the current field
      [fieldName]: e.target.value,
    }));
  };
  const onEquirySubmit = () => {
    if (contact.name && contact.email && contact.message) {
      api
        .post("/addEnquiry", {
          name: contact.name,
          email: contact.email,
          phone: contact.phone,
          message: contact.message,
          products: product.join(","),
          services: service.join(","),
        })
        .then((res) => {
          console.log(res);
          // alert('Record created successfully', 'success');
        })
        .catch(() => {
          alert("Unable to edit record.", "error");
        });
      console.log(contact);
    } else {
      alert("Please fill all required fields.", "error");
    }
  };

  //Email
  const sendMail = () => {
    if (contact.name && contact.email && contact.message) {
      const dynamic_template_data = {
        name: contact.name,
        email: contact.email,
        message: contact.message,
        phone: contact.phone,
      };
      api.post("/sendenquiryemail", { dynamic_template_data }).then(() => {
        alert(
          "Thanks for contacting us. We will respond to your enquiry as soon as possible"
        );
      });
      window.location.reload();
    } else {
      applyChanges();
    }
  };

  React.useEffect(() => {
    AOS.init();
    getBlogs();
    window.scrollTo(0, 0);
    setTimeout(() => {}, 1000);
  }, []);

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings3 = {
    dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const getBlogs = () => {
    api.get("/getBlogImage").then((res) => {
      setBlogs(res.data.data);
    });
    api.post("/getContent", { recordType: "Testimonialpart" }).then((res) => {
      setTestimonials(res.data.data);
    });
    api.post("/getContent", { recordType: "Home Products" }).then((res) => {
      setProducts(res.data.data);
    });
    api.post("/getContent", { recordType: "Our Services" }).then((res) => {
      setServices(res.data.data);
    });
    api.post("/getContent", { recordType: "Why Choose Us" }).then((res) => {
      setWhychooseus(res.data.data);
      // console.log(res.data.data)
    });
  };
  const getServiceDetail = (title) => {
    if (title === "Web/ E Commerce Development") {
      navigate("services/website-development-software");
    } else {
      var formated = title.toLowerCase().split(" ").join("-");

      navigate("/services/" + formated);
    }
  };
  const getFormatedText = (title) => {
    var formatedd = title.toLowerCase();
    return formatedd.split(" ").join("-");
  };

  return (
    <>
      <section className="hero-area hero-area-lg position-relative">
        <Helmet>
          <meta charSet="utf-8" />
          <link
            rel="icon"
            type="image/x-icon"
            href="/public/assets/images/logo/favicon-32x32.png"
          />
          <title>Zammy Zaif</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          ></meta>
        </Helmet>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 text-center text-lg-left mb-4 mb-lg-0">
              <div className="content">
                <h2 className="text-dark position-relative">
                Google SEO Consultant<span className="watermark">SEO</span>
                </h2>
                <p className="text-dark mb-4">
                Zammy Zaif, Google SEO Consultant, renowned worldwide for top-notch 
                Google ranking expertise. Achieve unmatched online visibility and growth.
                </p>
                <ul className="list-inline">
                  <li className="list-inline-item mx-1 my-2">
                    <a href="#" className="btn btn-primary">
                      {" Check Our Track Record "}
                      
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5">
              <img
                src="assets/images/banner/google-seo-consultant.webp"
                className="img-fluid w-10 shadow rounded-lg banner-image"
                alt="app-interface"
                data-aos="fade-left"
                data-aos-delay="100"
              />
            </div>
          </div>
        </div>
        {/* <img
          src="assets/images/homepage-3/banner-shape.png"
          className="img-fluid bg-shape-4 banner-shape"
          alt="shape"
        /> */}
      </section>
      <section style={{ paddingTop: 0, backgroundColor: '#7ac902' }} className="py-5">
  <div className="container">
    <div className="row">
      <div className="col-md-3 col-sm-6 mb-4 mb-md-0 text-center">
        <p className="h1 text-white font-weight-medium"><span className="counter" data-count="98">0</span>%</p>
        <h6 className="h4 text-dark font-weight-medium">Client Retention</h6>
      </div>
      <div className="col-md-3 col-sm-6 mb-4 mb-md-0 text-center">
        <p className="h1 text-white font-weight-medium"><span className="counter" data-count="16">0</span>+</p>
        <h6 className="h4 text-dark font-weight-medium">Year Of Service</h6>
      </div>
      <div className="col-md-3 col-sm-6 mb-4 mb-md-0 text-center">
        <p className="h1 text-white font-weight-medium"><span className="counter" data-count="60">0</span>+</p>
        <h6 className=" h4 text-dark font-weight-medium">Professionals</h6>
      </div>
      <div className="col-md-3 col-sm-6 mb-4 mb-md-0 text-center">
        <p className="h1 text-white font-weight-medium"><span className="counter" data-count="1280">0</span>+</p>
        <h6 className="h4 text-dark font-weight-medium">Satisfied clients</h6>
      </div>
    </div>
  </div>
</section>



<section style={{ paddingTop: 40 }} className="section">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-5">
        <header className="crumina-module crumina-heading">
          <div className="title-text-wrap">
            <h3 className="heading-title element-anime-fadeInUp-js">
              Website Seo Services - #1 Rank Track Records
            </h3>
          </div>
        </header>
      </div>
    </div>
  </div>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="blog-slider">
          <Slider {...settings}>
            {staticImagesRankOne.map((item, index) => (
              <div className="px-3 mb-5" key={index}>
              
                  <div className="card border-0 shadow rounded-xs">
                    {/* Static Image */}
                    <img
                      src={item.image}
                      className="img-fluid card-img-top"
                      alt={`SEO Expert ${index + 1}`}
                    />
                   <a
                href={item.link ? item.link : '#'} // Use item.link or fallback to '#'
                className="case-item-thumb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div style={{ marginTop: 5 }} className="text-center">
                  <h7 className="text-dark font-weight-high">
                    {item.des} {/* Display description here */}
                  </h7>
                </div>
              </a>
                    <div style={{ marginTop: -7 }} className="card-body">
                      <h6 className="card-title text-center">
                        {item.heading} {/* Display heading here */}
                      </h6>
                    </div>
                  </div>
               
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  </div>
</section>


    <section style={{ paddingTop: 40 }} className="section">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 md-mb-30">
					<header class="crumina-module crumina-heading mb-4">
						{/* <!-- CRUMINA HEADING TITLE --> */}
						<div class="title-text-wrap">
							<h2 class="heading-title element-anime-fadeInUp-js">Website Seo Rank Services</h2>
						</div>
						{/* <!-- /CRUMINA HEADING TITLE --> */}

						{/* <!-- CRUMINA HEADING DECORATION --> */}
						<div class="heading-decoration element-anime-fadeInUp-js"></div>
						{/* <!-- /CRUMINA HEADING DECORATION  --> */}
					</header>
          <p className="text-dark mb-4">Since 2014, we have been delivering 1st Rank SEO Services for websites, consistently moving clients from as far back as the 5th or even the 10th page to the No. 1 position within a 6-month timeframe for city-level rankings. For state, county, or global rankings, the timeframe may vary depending on the products or services, targeted audience, and level of competition. Led by Zammy Zaif, a recognized Global Google Ranking Expert, our team leverages years of expertise to ensure your website achieves and maintains top rankings in your desired market.</p>

					<div class="universal-btn-wrapper element-anime-opacity-js">
          <ul className="list-inline">
                  <li className="list-inline-item mx-1 my-2">
                    <a href="#" className="btn btn-primary">
                      {" Book a Consultation"}
                      
                    </a>
                  </li>
                </ul>
					</div>
				</div>
				<div className="col-lg-6">
              <img
                src="assets/images/banner/website-seo-services.webp"
                className="img-fluid w-10 shadow rounded-lg banner-image"
                alt="app-interface"
                data-aos="fade-left"
                data-aos-delay="100"
              />
            </div>
			</div>
		</div>
	</section>
  
<section style={{paddingTop:10}} className="section">
  <div className="container">
    <div className="row">
      <div className="col-12 text-center">
       
        <h2 className="section-title">Get Your Google SEO Estimate</h2>
      </div>
      <div className="col-12 text-center">
        <form action="#" className="row">
          <div className="col-lg-4">
            <input onChange={updateContactFields} type="text" 
            className="bg-light form-control mb-4" placeholder="Your Name*" name="name" />
          </div>
          <div className="col-lg-4">
            <input  onChange={updateContactFields} type="text" className="bg-light form-control mb-4" placeholder="Your Email*" name="email"/>
          </div>
          <div className="col-lg-4">
            <input  onChange={updateContactFields} type="text" className="bg-light form-control mb-4" placeholder="Your Phone" name="phone"/>
          </div>
          <div className="col-lg-4">
                              <select onChange={updateContactFields} type="text" className="bg-light form-control mb-4" placeholder="Seo Service" name="service">
                                        <option value=" Website SEO Services"> Website SEO Services</option>
                                        <option value="GMB SEO Services">GMB SEO Services</option>
                              </select>
												
											</div>
             <div className="col-lg-4">
            <input  onChange={updateContactFields} type="text" className="bg-light form-control mb-4" placeholder="GBP or Website URL" name="url"/>
          </div>
          <div className="col-lg-4">
            <input  onChange={updateContactFields} type="text" className="bg-light form-control mb-4" placeholder="Subject" name="subject"/>
          </div>
          <div className="col-lg-12">
            <textarea  onChange={updateContactFields} className="bg-light form-control mb-4" placeholder="Message*" name="message"></textarea>
          </div>
                   
          <div className="col-12">
            <button onClick={()=>{
             
              onEquirySubmit();
              sendMail();
              
            }} type="button" className="btn btn-primary">Submit Now</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<section style={{paddingTop:30}} className="section">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 md-mb-30">
					<header class="crumina-module crumina-heading mb-4">
						{/* <!-- CRUMINA HEADING TITLE --> */}
						<div class="title-text-wrap">
							<h2 class="heading-title element-anime-fadeInUp-js">#1 Google SEO Consultant</h2>
						</div>
						{/* <!-- /CRUMINA HEADING TITLE -->

						<!-- CRUMINA HEADING DECORATION --> */}
						<div class="heading-decoration element-anime-fadeInUp-js"></div>
						{/* <!-- /CRUMINA HEADING DECORATION  --> */}
					</header>
					<p class="text-l-justify mb-10">Zammy Zaif has been a leading Google SEO consultant with a wealth of experience in the field, having been active in SEO since 2008. From 2014, he has consistently achieved first-rank results for clients, demonstrating his expertise and commitment to delivering top-tier SEO performance. Recognized as a Global Google SEO Expert from 2018, Zammy Zaif offers unparalleled insights and strategies to elevate your brand’s presence on Google. Consult with him to leverage his extensive knowledge and drive significant growth for your business.</p>

          <div class="universal-btn-wrapper element-anime-opacity-js">
          <ul className="list-inline">
                  <li className="list-inline-item mx-1 my-2">
                    <a href="#" className="btn btn-primary">
                      {" Book a Consultation"}
                      
                    </a>
                  </li>
                </ul>
					</div>
				</div>
				<div className="col-lg-6">
              <img
                src="assets/images/banner/expert-google-search.webp"
                className="img-fluid w-10 shadow rounded-lg banner-image"
                alt="app-interface"
                data-aos="fade-left"
                data-aos-delay="100"
              />
            </div>
			</div>
		</div>
	</section>

  <section style={{ paddingTop: 0, backgroundColor: '#7ac902' }} className="py-5">
  <div className="container">
    <div className="row">
      <div className="col-md-3 col-sm-6 mb-4 mb-md-0 text-center">
        <p className="h1 text-white font-weight-medium"><span className="counter" data-count="25">0</span></p>
        <h6 className="h4 text-dark font-weight-medium">Ranking</h6>
      </div>
      <div className="col-md-3 col-sm-6 mb-4 mb-md-0 text-center">
        <p className="h1 text-white font-weight-medium"><span className="counter" data-count="400">0</span>%</p>
        <h6 className="h4 text-dark font-weight-medium">Impression</h6>
      </div>
      <div className="col-md-3 col-sm-6 mb-4 mb-md-0 text-center">
        <p className="h1 text-white font-weight-medium"><span className="counter" data-count="20">0</span>%</p>
        <h6 className="h4 text-dark font-weight-medium">Clicks</h6>
      </div>
      <div className="col-md-3 col-sm-6 mb-4 mb-md-0 text-center">
        <p className="h1 text-white font-weight-medium"><span className="counter" data-count="100">0</span>%</p>
        <h6 className="h4 text-dark font-weight-medium">Traffic</h6>
      </div>
    </div>
  </div>
</section>
<section style={{ paddingTop: 40 }} className="section">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-5">
        <header className="crumina-module crumina-heading">
          <div className="title-text-wrap">
            <h3 className="heading-title element-anime-fadeInUp-js">
            GMB Seo Services - #1 Rank Track Records
            </h3>
          </div>
        </header>
      </div>
    </div>
  </div>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="blog-slider">
          <Slider {...settings}>
            {staticImages.map((item, index) => (
              <div className="px-3 mb-5" key={index}>
                <Link to="/blogdetail" className="link">
                  <div className="card border-0 shadow rounded-xs">
                    {/* Static Image */}
                    <img
                      src={item.image}
                      className="img-fluid card-img-top"
                      alt={`SEO Expert ${index + 1}`}
                    />
                    <div className="card-body">
                      <h6 className="card-title text-center">
                        {item.heading} {/* Display heading here */}
                      </h6>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  </div>
</section>
    <section class="large-padding section-anime-js text-m-center">
		<div class="container">
			<div class="row flex-row-reverse align-items-center">
				<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 md-mb-30">
					<header class="crumina-module crumina-heading mb-4">
						{/* <!-- CRUMINA HEADING TITLE --> */}
						<div class="title-text-wrap">
							<h3 class="heading-title element-anime-fadeInUp-js">Google Maps Rank Services</h3>
						</div>
					</header>
					<p class="text-l-justify mb-10">Google Business Profile (GBP) Pack 3 services offer a comprehensive approach to boosting a business's local online visibility. This package specializes in first-rank local SEO services, focusing on optimizing GBP profiles to achieve top positions in Google's local search results, commonly referred to as the "Local Pack." Services include complete GBP profile optimization, tailored local SEO strategies for specific locations, ongoing GBP management to maintain freshness and relevance, and prompt customer feedback responses. By leveraging these services, businesses can significantly enhance their local presence, attract more customers, improve their overall online reputation, and ultimately generate more calls and directions.</p>
				</div>
        <div className="col-lg-5">
              <img
                src="assets/images/banner/17-local-details.svg"
                className="img-fluid w-10 shadow rounded-lg banner-image"
                alt="app-interface"
                data-aos="fade-left"
                data-aos-delay="100"
              />
            </div>
			</div>
		</div>
	</section>
	
	
  <section style={{ paddingTop: 200 }} className="section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 mb-7 mb-md-0">
            <div className="seo-results-thumb">
              {/* Render the doughnut chart */}
              <Chart
                options={optionsdoughnut.options}
                series={optionsdoughnut.series}
                type="donut"
                height={350}
              />
            </div>
          </div>
          
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4 mb-md-0">
            <header className="crumina-module crumina-heading mb-1">
              {/* Heading section */}
              <div className="title-text-wrap">
                <h3 className="heading-title element-anime-fadeInUp-js">Google Rank Services</h3>
              </div>
          </header>
            <p>
                SEO services often focus on achieving first-page results, but if you’re already on the first page and still not receiving leads and inquiries due to not being at the top, you’ve come to the right place.
              </p>

            <p className="mb-0 text-l-justify">
              After a thorough consultation regarding your SEO requirements, we offer specialized SEO Ranking Services designed to help your website secure the top position, particularly the first rank, in Google Search Results, ensuring maximum visibility and lead generation.
            </p>
          </div>

         <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
  <div className="seo-results mb-3">
    <div className="seo-results-item d-flex align-items-center mb-2">
      <img
        loading="lazy"
        className="crumina-icon mr-3"
        src="assets/images/banner/icon7.svg"
        alt="CTR"
        style={{ width: '40px', height: '40px' }} // Adjust the size as needed
      />
      <div className="h5 seo-results-item-info">0.09% CTR</div>
    </div>

    <div className="seo-results-item d-flex align-items-center mb-2">
      <img
        loading="lazy"
        className="crumina-icon mr-3"
        src="assets/images/banner/icon8.svg"
        alt="CPA"
        style={{ width: '40px', height: '40px' }} // Adjust the size as needed
      />
      <div className="h5 seo-results-item-info">20% less CPA</div>
    </div>

    <div className="seo-results-item d-flex align-items-center">
      <img
        loading="lazy"
        className="crumina-icon mr-3"
        src="assets/images/banner/icon9.svg"
        alt="ROI"
        style={{ width: '40px', height: '40px' }} // Adjust the size as needed
      />
      <div className="h5 seo-results-item-info">272% ROI</div>
    </div>
  </div>
  <div className="universal-btn-wrapper element-anime-opacity-js">
              <ul className="list-inline">
                <li className="list-inline-item mx-1 my-2">
                  <a href="#" className="btn btn-primary">
                    {"Book an SEO Consultation"}
                  </a>
                </li>
              </ul>
            </div>
           </div>

        </div>
      </div>
    </section>

  
  {/* <section style={{paddingTop:30}} className="section">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<header class="crumina-module crumina-heading text-center mb-4">
				
						<div class="title-text-wrap">
							<h2 class="heading-title element-anime-fadeInUp-js">Tips for Hiring a Google SEO Consultant</h2>
						</div>
                
            </header>

				<div class="row">
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4 mb-md-0">
					<div class="crumina-module crumina-info-box info-box--standard">
						<div class="info-box-content">
							<h4 class="info-box-title">Define Your Goals</h4>
							<p class="info-box-text">Clearly outline your SEO objectives. Are you aiming to increase organic traffic, improve local search rankings, or boost conversions?</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4 mb-md-0">
					<div class="crumina-module crumina-info-box info-box--standard">
						<div class="info-box-content">
							<h4 class="info-box-title">Research Potential Candidates</h4>
							<p class="info-box-text">Thoroughly investigate potential consultants. Check their portfolios, case studies, and client testimonials.</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
					<div class="crumina-module crumina-info-box info-box--standard">
						<div class="info-box-content">
							<h4 class="info-box-title">Assess Expertise</h4>
							<p class="info-box-text">Ensure the consultant has a deep understanding of current SEO best practices. They should be well-versed in technical SEO</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4 mb-md-0">
					<div class="crumina-module crumina-info-box info-box--standard">
						<div class="info-box-content">
							<h4 class="info-box-title">Evaluate Communication Skills</h4>
							<p class="info-box-text">Effective communication is crucial for a successful SEO partnership. The consultant should be able to explain complex SEO.</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4 mb-md-0">
					<div class="crumina-module crumina-info-box info-box--standard">
						<div class="info-box-content">
							<h4 class="info-box-title">Discuss Transparency and Reporting</h4>
							<p class="info-box-text">Inquire about the consultant's reporting methods. They should provide regular updates on progress, including key metrics.</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
					<div class="crumina-module crumina-info-box info-box--standard">
						<div class="info-box-content">
							<h4 class="info-box-title">Set Clear Expectations</h4>
							<p class="info-box-text">Establish clear expectations regarding timelines, deliverables, and budget. Discuss your desired ROI and how the consultant plans.</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4 mb-md-0">
					<div class="crumina-module crumina-info-box info-box--standard">
						<div class="info-box-content">
							<h4 class="info-box-title">Consider Long-Term Partnership</h4>
							<p class="info-box-text">SEO is an ongoing process. Look for a consultant who views your business as a long-term partner rather than a one-time client.</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4 mb-md-0">
					<div class="crumina-module crumina-info-box info-box--standard">
						<div class="info-box-content">
							<h4 class="info-box-title">Check for Ethical Practices</h4>
							<p class="info-box-text">Ensure the consultant adheres to white-hat SEO techniques. Avoid those who promise quick results through black-hat tactics.</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
					<div class="crumina-module crumina-info-box info-box--standard">
						<div class="info-box-content">
							<h4 class="info-box-title">Get Recommendations</h4>
							<p class="info-box-text">Seek recommendations from trusted business contacts or online communities. Personal referrals can provide valuable insights.</p>
						</div>
					</div>
				</div>
			</div>
			
				</div>
			</div>
		</div>
	</section> */}

<section style={{ paddingTop: 30 }} className="section5 bg-light">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-1">
        <header className="crumina-module crumina-heading">
          <div className="title-text-wrap">
            <h3 className="heading-title element-anime-fadeInUp-js">
              Tips for Hiring a Google SEO Consultant
            </h3>
          </div>
        </header>
      </div>
    </div>

    <div className="row">
      {/* Map through an array of tips for cleaner code */}
      {[
        {
          title: "Define Your Goals",
          text:
            "Clearly outline your SEO objectives. Are you aiming to increase organic traffic, improve local search rankings, or boost conversions?",
        },
        {
          title: "Research Potential Candidates",
          text:
            "Thoroughly investigate potential consultants. Check their portfolios, case studies, and client testimonials.",
        },
        {
          title: "Assess Expertise",
          text:
            "Ensure the consultant has a deep understanding of current SEO best practices. They should be well-versed in technical SEO.",
        },
        {
          title: "Evaluate Communication Skills",
          text:
            "Effective communication is crucial for a successful SEO partnership. The consultant should be able to explain complex SEO concepts clearly.",
        },
        {
          title: "Discuss Transparency and Reporting",
          text:
            "Inquire about the consultant's reporting methods. They should provide regular updates on progress, including key metrics.",
        },
        {
          title: "Set Clear Expectations",
          text:
            "Establish clear expectations regarding timelines, deliverables, and budget. Discuss your desired ROI and how the consultant plans to achieve it.",
        },
        {
          title: "Consider Long-Term Partnership",
          text:
            "SEO is an ongoing process. Look for a consultant who views your business as a long-term partner rather than a one-time client.",
        },
        {
          title: "Check for Ethical Practices",
          text:
            "Ensure the consultant adheres to white-hat SEO techniques. Avoid those who promise quick results through black-hat tactics.",
        },
        {
          title: "Get Recommendations",
          text:
            "Seek recommendations from trusted business contacts or online communities. Personal referrals can provide valuable insights.",
        },
      ].map((tip, index) => (
        <div className="col-lg-4 col-md-1 col-sm-1 mb-1" key={index}>
          <div className="info-box card7 border-0 shadow1-sm h-100">
            <div className="card-body">
              <h5 className="info-box-title text-primary">{tip.title}</h5>
              <p className="info-box-text text-muted">{tip.text}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>



<section style={{ paddingTop: 100 }} className="section">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="starter-template">

          {/* Navigation Tabs */}
          <ul className="nav nav-tabs" id="seoTab" role="tablist">
    <li className="nav-item">
        <a className="nav-link custom-nav-link active" id="google-map-seo-tab" data-toggle="tab" href="#google-map-seo" role="tab" aria-controls="google-map-seo" aria-selected="true">
            <i className="fa fa-map"></i> Google Map SEO
        </a>
    </li>
    <li className="nav-item">
        <a className="nav-link custom-nav-link" id="city-based-seo-tab" data-toggle="tab" href="#city-based-seo" role="tab" aria-controls="city-based-seo" aria-selected="false">
            <i className="fa fa-city"></i> City Based SEO
        </a>
    </li>
    <li className="nav-item">
        <a className="nav-link custom-nav-link" id="state-level-seo-tab" data-toggle="tab" href="#state-level-seo" role="tab" aria-controls="state-level-seo" aria-selected="false">
            <i className="fa fa-map-signs"></i> State Level SEO
        </a>
    </li>
    <li className="nav-item">
        <a className="nav-link custom-nav-link" id="country-level-seo-tab" data-toggle="tab" href="#country-level-seo" role="tab" aria-controls="country-level-seo" aria-selected="false">
            <i className="fa fa-globe"></i> Country Level SEO
        </a>
    </li>
    <li className="nav-item">
        <a className="nav-link custom-nav-link" id="global-seo-tab" data-toggle="tab" href="#global-seo" role="tab" aria-controls="global-seo" aria-selected="false">
            <i className="fa fa-universal-access"></i> Global SEO
        </a>
    </li>
</ul>


          {/* Tab Content */}
          <div className="tab-content pt-4" id="seoTabContent">

            {/* Google Map SEO Tab */}
            <div className="tab-pane fade show active" id="google-map-seo" role="tabpanel" aria-labelledby="google-map-seo-tab">
              <div className="row align-items-center">
                <div className="col-lg-8 mb-3">
                  <h3>Local SEO Ranking</h3>
                  <p>Maximize your reach with our GBP Local SEO services. According to recent reports, GBP is the most popular platform, responsible for driving 80% of local targeted traffic to neighborhood businesses. Specializing in optimizing your Google Business Profile with GPS settings, we assure your business ranks high in local searches. Our targeted GBP Local SEO strategies focus on building your local brand by driving traffic and attracting new customers to your physical location or service area. With proven expertise, we help you stand out in local search results, generating more convertible leads for increasing revenue.</p>
                  <a href="#" className="btn btn-blueaqua custom-button">Book an SEO Consultation</a>
                </div>
                {/* <div className="card text-center border-0 shadow">
                    <div className="card-body">
                       <h5 className="card-title">GBP Local SEO</h5>
                  <ul className="list-unstyled">
                <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i> 12-24 Months Timeframe</li>
                  <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i> Global Level 1st Rank</li>
                <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i> 1st Rank for 75 Keywords</li>
                  <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i> 1st Page Rank for 125 Keys</li>
                <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i> Snippet Featured Results</li>
                  <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i> 1500 HQ White Hat Backlinks</li>
                </ul>
                     </div>
                </div> */}

<div className="col-lg-4">
  <div className="card text-center border-0 shadow">
    <div className="card-body">
      <div className="p-3 custom-bg text-white">
        <h5 className="card-title text-white mb-0">GBP Local SEO</h5>
      </div>
      <ul className="list-unstyled mt-3 text-start">
      <li className="d-flex align-items-center mb-2">
  <i className="fa fa-star custom-star star-spacing"></i>
  45 Days Timeframe
</li>
<li className="d-flex align-items-center mb-2">
  <i className="fa fa-star custom-star star-spacing"></i>
  5-15 Miles of Area Radius
</li>
<li className="d-flex align-items-center mb-2">
  <i className="fa fa-star custom-star star-spacing"></i>
  5 Major Category Keywords
</li>
<li className="d-flex align-items-center mb-2">
  <i className="fa fa-star custom-star star-spacing"></i>
  5 Business Related Keywords
</li>
<li className="d-flex align-items-center mb-2">
  <i className="fa fa-star custom-star star-spacing"></i>
  1 Google Business Profile
</li>
<li className="d-flex align-items-center mb-2">
  <i className="fa fa-star custom-star star-spacing"></i>
  Website Ranking
</li>

        {/* <li>Website Ranking</li> */}
      </ul>
      <div className="price mt-3 p-3 border rounded shadow-sm bg-light">
        <span className="text-muted">Starts from: </span>
        <strong>$750</strong>
      </div>
    </div>
  </div>
</div>


              </div>
            </div>

            {/* City Based SEO Tab */}
            <div className="tab-pane fade" id="city-based-seo" role="tabpanel" aria-labelledby="city-based-seo-tab">
              <div className="row align-items-center">
                <div className="col-lg-8 mb-3">
                  <h3>City Based SEO Rankings</h3>
                  <p>Our City Based SEO Ranking service is designed to deliver results within a 3-6 month timeframe, focusing on a specific city or district. We restructure your website according to Google's latest guidelines and create original, plagiarism-free content customized for your business. By the end of the third month, you will start seeing results on the first page of search engines. We aim to achieve 1st Rank for 25 keywords and 1st page Rank for 75 keywords. During this 6 month period, we will also manage and optimize one Google Business Profile and build 300 high-quality, white-hat backlinks.</p>
                  <a href="#" className="btn btn-blueaqua custom-button">Book an SEO Consultation</a>
                </div>
                <div className="col-lg-4">
                  <div className="card text-center border-0 shadow">
                    <div className="card-body">
                    <div className="p-3 custom-bg">
                   <h5 className="card-title text-white mb-0">City Based SEO</h5>
                   </div>

                      <ul className="list-unstyled mt-3 text-start">
                     <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>3-6 Months Timeframe</li>
                      <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>A Particular City or District</li>
                      <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>1st Rank for 25 Keywords</li>
                      <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>1st page Rank for 75 Keywords</li>
                      <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>1 Google Business Profile</li>
                      <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>300 HQ White Hat Backlinks</li>
                      </ul>
                      <div className="price mt-3 p-3 border rounded shadow-sm bg-light">
                      <span className="text-muted">Starts from: </span><strong> $999</strong> <span className="text-muted">/month</span>
                     </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* State Level SEO Tab */}
            <div className="tab-pane fade" id="state-level-seo" role="tabpanel" aria-labelledby="state-level-seo-tab">
              <div className="row align-items-center">
                <div className="col-lg-8 mb-3">
                  <h3>State Ranking SEO</h3>
                  <p>Our State Level SEO Ranking service is designed to deliver results within a 6-9 month timeframe. Understanding the project requirements, we initially focus on optimizing your website for your specific city by redesigning it according to the Google's guidelines. By updating original content which includes service-oriented keywords to promote your business organically. By the end of the 3rd month, you will begin to see your website appearing on the first page of search results. Within 6 months, we assure achieving the 1st rank for 25 keywords and 1st-page rankings for 75 keywords at the city level. In the following 3 months, we target an additional 25 major keywords for 1st Rank and 25 keywords for 1st-page rankings at the state level. Our services also include managing and optimizing up to 5 Google Business Profiles and creating 500 high-quality white-hat backlinks within this 9-month period.</p>
                  <a href="#" className="btn btn-blueaqua custom-button">Book an SEO Consultation</a>
                </div>
                <div className="col-lg-4">
                  <div className="card text-center border-0 shadow">
                    <div className="card-body">
                    <div className="p-3 custom-bg text-white">
                    <h5 className="card-title text-white mb-0">State Ranking SEO</h5>
                     </div>
                     <ul className="list-unstyled mt-3 text-start">
                     <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>6-9 Months Timeframe</li>
                        <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>State Level 1st Rank</li>
                        <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>1st Rank for 25 Keywords</li>
                        <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>1st Page Rank for 125 Key</li>
                        <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>1-5 Google Business Profiles</li>
                        <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>500 HQ White Hat Backlinks</li>
                      </ul>
                      <div className="price mt-3 p-3 border rounded shadow-sm bg-light">
                      <span className="text-muted">Starts from: </span><strong>$1350</strong> <span className="text-muted">/month</span>
                     </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Country Level SEO Tab */}
            <div className="tab-pane fade" id="country-level-seo" role="tabpanel" aria-labelledby="country-level-seo-tab">
              <div className="row align-items-center">
                <div className="col-lg-8 mb-3">
                  <h3>Country SEO Ranking</h3>
                  <p>We offer a 9-12 month national SEO ranking service. Starting the process by concentrating on your local area to establish a solid foundation. Your website will be redesigned to meet Google's standards and filled with original, informative content. Demonstrating our expertise, you will see initial improvements in local search rankings within first 3 months. In six months, we will aim to rank your business first for 25 local keywords and on the first page for 75 keywords. Over the next six months, we will expand our efforts to achieve top rankings for 25 keywords at the state level and another 25 nationally. Throughout the process, we will also manage up to 10 Google Business Profiles while building 750 high-quality backlinks.</p>
                  <a href="#" className="btn btn-blueaqua custom-button ">Book an SEO Consultation</a>
                </div>
                <div className="col-lg-4">
                  <div className="card text-center border-0 shadow">
                    <div className="card-body">
                      <div className="p-3 custom-bg text-white">
                    <h5 className="card-title text-white mb-0">National SEO Ranking</h5>
                     </div>
                     <ul className="list-unstyled mt-3 text-start">
                     <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>9-12 Months Timeframe</li>
                        <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>Country Level 1st Rank</li>
                        <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>1st Rank for 50 Keywords</li>
                        <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>1st Page Rank for 75 Keywords</li>
                        <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>1-10 Google Business Profiles</li>
                        <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>750 HQ White Hat Backlinks</li>
                      </ul>
                      <div className="price mt-3 p-3 border rounded shadow-sm bg-light">
                      <span className="text-muted">Starts from: </span><strong>$1700</strong> <span className="text-muted">/month</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Global SEO Tab */}
            <div className="tab-pane fade" id="global-seo" role="tabpanel" aria-labelledby="global-seo-tab">
              <div className="row align-items-center">
                <div className="col-lg-8 mb-3">
                  <h3>Global SEO Ranking</h3>
                  <p>Our Global Level 1st Rank SEO Services for general keywords is a comprehensive program with a timeframe of 12-24 months. This service targets getting the 1st rank for 75 keywords and secure a spot on the 1st page for 125 keywords, effectively increasing your global online reach. We also focus on obtaining Snippet Featured Results to highlight your content prominently in search results. We will also build 1500 high-quality white hat backlinks to strengthen your website’s authority and ranking. This strategic approach ensures a strong and sustainable improvement in your global search engine performance.</p>
                  <a href="#" className="btn btn-blueaqua custom-button"> {/* Use custom class */}
                  Book an SEO Consultation
                 </a>
                </div>
                <div className="col-lg-4">
                  <div className="card text-center border-0 shadow">
                    <div className="card-body">
                      <div className="p-3 custom-bg text-white">
                    <h5 className="card-title text-white mb-0">Global SEO Ranking</h5>
                     </div>
                     <ul className="list-unstyled mt-3 text-start">
                     <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>12-24 Months Timeframe</li>
                        <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>Global Level 1st Rank</li>
                        <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>1st Rank for 75 Keywords</li>
                        <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>1st Page Rank for 125 Keys</li>
                        <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>Snippet Featured Results</li>
                        <li className="d-flex align-items-center mb-2">
                      <i className="fa fa-star custom-star star-spacing"></i>1500 HQ White Hat Backlinks</li>
                      </ul>
                      <div className="price mt-3 p-3 border rounded shadow-sm bg-light">
                      <span className="text-muted">Starts from: </span><strong>$1500</strong> <span className="text-muted">/month</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section style={{ paddingTop: 80 }} className="section5 bg-light">
            <Row>

            <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-5">
        <header className="crumina-module crumina-heading">
          <div className="title-text-wrap">
            <h3 className="heading-title element-anime-fadeInUp-js">
            Supercharge SEO Ranks in 4 Steps
            </h3>
          </div>
        </header>
      </div>
    </div>
    </div>
            <div class="col-lg-3" >
              
                <ul class="list-unstyled">
                  <li
                    class="d-flex mb-4"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                   
                    <div class="pl-3" style={{ textAlign: 'center' }}>
                    <img src={search} alt="Address" width="50%" height="50%"  />
                  
                      <h6 class="text-dark mb-4" style={{ marginTop: '10px' }}>Search</h6>
                      <ul class="list-unstyled">
                        <li>{'Search "Google Ranking Expert" on Google and click the top result, www.it2.tv. Check out our first ranks, client keyword successes & proven track record in various industries.'}</li>
                      </ul>
                    </div>
                  </li>
              
                
                </ul>
              </div>
            
            <div class="col-lg-3">
           
                <ul class="list-unstyled">
                  <li
                    class="d-flex mb-4"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                  
                    <div class="pl-3" style={{ textAlign: 'center' }}>
                    <img src={contactimage} alt="Address" width="50%" height="50%"  />
                  
                      <h6 class="text-dark mb-4" style={{ marginTop: '10px' }}>Contact</h6>
                      <ul class="list-unstyled">
                        <li>{'If you are impressed, contact us to discuss your SEO needs. Select from our customized ranking plans designed specifically for your business. We strategize to achieve top rank.'}</li>
                    
                      </ul>
                    </div>
                  </li>
                
                
                </ul>
              </div>
          
            <div class="col-lg-3">
             
                <ul class="list-unstyled">
                  <li
                    class="d-flex mb-4"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                  
                    <div class="pl-3" style={{ textAlign: 'center' }}>
                    <img src={optimize} alt="Address" width="50%" height="50%"  />
                  
                      <h6 class="text-dark mb-4" style={{ marginTop: '10px' }}>Optimize</h6>
                      <ul class="list-unstyled">
                        <li>By analyzing your competitors, we'll optimize your business profile and website to achieve top rankings. Our techniques boost your online visibility and ensure #1 spot on Google.</li>
                    
                      </ul>
                    </div>
                  </li>
                
                
                </ul>
              </div>
         
            <div class="col-lg-3">
         
                <ul class="list-unstyled">
                  <li
                    class="d-flex mb-4"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                   
                    <div class="pl-3" style={{ textAlign: 'center' }}>
                    <img src={result} alt="Address" width="50%" height="50%"  />
                  
                      <h6 class="text-dark mb-4" style={{ marginTop: '10px' }}>Result</h6>
                      <ul class="list-unstyled">
                        <li>Watch your business rise in rankings and revenue with our expert SEO consultant's proven strategies. Experience organic growth and increased sales, assuring long-term success.</li>
           
                      </ul>
                    </div>
                  </li>
                 
   
                </ul>
              </div>
        
            </Row>
            </section>
            <section style={{ paddingTop: 80 }} className="section">
            <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-5">
        <header className="crumina-module crumina-heading">
          <div className="title-text-wrap">
            <h3 className="heading-title element-anime-fadeInUp-js">
            FAQ's
            </h3>
          </div>
        </header>
      </div>
    </div>
    </div>
  
    <div className="row">
      {/* FAQ Side */}
      <div className="col-md-6">
        <div className="accordion" id="faqAccordion">
          {/* FAQ 1 */}
          <div className="card mb-3">
            <div className="card-header" id="headingOne">
              <h5 className="mb-0">
                <button
                  className="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  style={{ color: "#27c6cc", textDecoration: "none" }}
                >
                  Who is the world's best google ranking expert?
                </button>
              </h5>
            </div>
            <div
              id="collapseOne"
              className="collapse show"
              aria-labelledby="headingOne"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
                The world's best Google ranking expert is known for their deep understanding of SEO strategies and Google's ranking algorithms.
              </div>
            </div>
          </div>

          {/* FAQ 2 */}
          <div className="card mb-3">
            <div className="card-header" id="headingTwo">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                  style={{ color: "#27c6cc", textDecoration: "none" }}
                >
                  Who is a Google ranking expert?
                </button>
              </h5>
            </div>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
                A Google ranking expert helps websites improve their search engine rankings through organic SEO practices.
              </div>
            </div>
          </div>

          {/* FAQ 3 */}
          <div className="card mb-3">
            <div className="card-header" id="headingThree">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                  style={{ color: "#27c6cc", textDecoration: "none" }}
                >
                  Why do we need a Google Ranking Expert?
                </button>
              </h5>
            </div>
            <div
              id="collapseThree"
              className="collapse"
              aria-labelledby="headingThree"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
                A Google ranking expert can help businesses grow by improving their visibility in search engine results.
              </div>
            </div>
          </div>

          {/* FAQ 4 */}
          <div className="card mb-3">
            <div className="card-header" id="headingFour">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                  style={{ color: "#27c6cc", textDecoration: "none" }}
                >
                  What services do Google Ranking Experts provide?
                </button>
              </h5>
            </div>
            <div
              id="collapseFour"
              className="collapse"
              aria-labelledby="headingFour"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
                Google ranking experts provide SEO audits, keyword analysis, content optimization, and backlink strategies.
              </div>
            </div>
          </div>

          {/* FAQ 5 */}
          <div className="card mb-3">
            <div className="card-header" id="headingFive">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                  style={{ color: "#27c6cc", textDecoration: "none" }}
                >
                  How long does it take to see results with Google Ranking Services?
                </button>
              </h5>
            </div>
            <div
              id="collapseFive"
              className="collapse"
              aria-labelledby="headingFive"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
                SEO results take time and depend on various factors, but improvements can typically be seen in a few months.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Image Side */}
      <div className="col-md-6 text-center">
       <div className="card mb-3">
            <div className="card-header" id="headingsix">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapsesix"
                  aria-expanded="false"
                  aria-controls="collapsesix"
                  style={{ color: "#27c6cc", textDecoration: "none" }}
                >
                 How long will Global SEO Ranking take for general keywords?
                </button>
              </h5>
            </div>
            <div
              id="collapsesix"
              className="collapse"
              aria-labelledby="headingsix"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
              Global SEO Ranking for general keywords typically takes 12 to 24 months. This extended timeframe allows us to implement and refine strategies that secure top positions on a global scale.
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-header" id="headingseven">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseseven"
                  aria-expanded="false"
                  aria-controls="collapseseven"
                  style={{ color: "#27c6cc", textDecoration: "none" }}
                >
                  Can you guarantee first-rank results for all types of keywords?
                </button>
              </h5>
            </div>
            <div
              id="collapseseven"
              className="collapse"
              aria-labelledby="headingseven"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
              We specialize in securing first-rank results for a wide range of keywords, including local, city-based, state-level, national, and global. Our proven track record demonstrates our capability to achieve top positions consistently.
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-header" id="headingeight">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseeight"
                  aria-expanded="false"
                  aria-controls="collapseeight"
                  style={{ color: "#27c6cc", textDecoration: "none" }}
                >
                  What if my website doesn't get the promised results?
                </button>
              </h5>
            </div>
            <div
              id="collapseeight"
              className="collapse"
              aria-labelledby="headingeight"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
              If we do not achieve the promised results, we will continue to work on improving your rankings at no additional cost until we meet our commitment. Our goal is to ensure your complete satisfaction with our services.
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-header" id="headingnine">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapsenine"
                  aria-expanded="false"
                  aria-controls="collapsenine"
                  style={{ color: "#27c6cc", textDecoration: "none" }}
                >
                 How do you decide the best SEO plan for my business?
                </button>
              </h5>
            </div>
            <div
              id="collapsenine"
              className="collapse"
              aria-labelledby="headingnine"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
              We start with a comprehensive consultation to understand your specific SEO needs and business goals. Based on this, we develop a customized SEO strategy that aligns with your objectives and targets the most effective keywords for your niche.
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-header" id="headingten">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseten"
                  aria-expanded="false"
                  aria-controls="collapseten"
                  style={{ color: "#27c6cc", textDecoration: "none" }}
                >
                What support do you provide after reaching top rankings?
                </button>
              </h5>
            </div>
            <div
              id="collapseten"
              className="collapse"
              aria-labelledby="headingten"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
              After achieving top rankings, we provide ongoing support and optimization to maintain and improve your SEO performance. This includes regular performance reviews, adjustments to strategies as needed, and continuous monitoring to ensure sustained success.
              </div>
            </div>
          </div>
      </div>
    </div>
 
</section>
   
    
      
    </>
  );
}
