import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import { Checkbox } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";
import api from "../../../constants/api";
// //import moment from 'moment';
import ReactHtmlParser from "react-html-parser";
import { Link, useNavigate } from "react-router-dom";
import imageBase from "../../../constants/image.js";
import { Helmet } from "react-helmet";
import seoleadcon from "../../../../src/assets/images/seo-lead-conversion.webp";
import seoaudit from "../../../../src/assets/images/seo-audit.webp";
import localseo from "../../../../src/assets/images/local-seo.webp";
import increasingkeyword from "../../../../src/assets/images/increasing-keywords-rank.webp";
import contentwriter from "../../../../src/assets/images/content-writer.webp";
import googlerankingservice from "../../../../src/assets/images/google-ranking-services.webp";
import videosvg from "../../../../src/assets/images/video-control.svg";
import bestseo from "../../../../src/assets/images/best-seo-expert.webp";
import socialmarketing from "../../../../src/assets/images/social-media-marketing.webp";
import digitalmarketing from "../../../../src/assets/images/digital-marketing.webp";
import mountain1 from "../../../../src/assets/images/mountain2.png";
import mountain2 from "../../../../src/assets/images/mountain1.png";
import whoiszammy from "../../../../src/assets/images/who-is-zammy-zaif.webp";
import hydralic from "../../../../src/assets/images/hydralic-oman.webp";
import nabtakar from "../../../../src/assets/images/nabtaker.webp";
import venture from "../../../../src/assets/images/venture-partner.webp";
import avatar from "../../../../src/assets/images/avatar.png";
import testimonial1 from "../../../../src/assets/images/testimonial1.png";
import testimonial2 from "../../../../src/assets/images/testimonial2.png";
import seoscore2 from "../../../../src/assets/images/seoscore2.png";
import seoscore3 from "../../../../src/assets/images/seoscore3.png";
import faq from "../../../../src/assets/images/faq.webp";
import slider2 from "../../../../src/assets/images/slider2.png";
import slider3 from "../../../../src/assets/images/slider3.png";
import zammy from "../../../../src/assets/images/zammy.webp";

export default function Home() {
  const [blogs, setBlogs] = useState([]);
  const [testimonial, setTestimonials] = useState([]);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [choose, setWhychooseus] = useState([]);

  const navigate = useNavigate();

  const [contact, setContact] = React.useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Array of static images

  // Define your questions and answers
  const faqData = [
    {
      question: "What is SEO?",
      answer:
        "SEO stands for Search Engine Optimization. It is the process of optimizing your website to rank higher in search engine results.",
    },
    {
      question: "How does SEO work?",
      answer:
        "SEO works by optimizing your website’s content, structure, and technical aspects to be more attractive to search engines, increasing your chances of ranking higher.",
    },
    {
      question: "How long does it take to see results from SEO?",
      answer:
        "SEO is a long-term strategy. It may take a few months to start seeing significant results depending on competition and the effectiveness of your efforts.",
    },
    {
      question: "Do I need to hire an SEO expert?",
      answer:
        "Hiring an SEO expert can help you achieve faster and more effective results, but it's possible to learn and apply basic SEO techniques yourself.",
    },
  ];

  // State to keep track of which question is expanded
  const [activeIndex, setActiveIndex] = useState(null);

  // Function to toggle active question
  const toggleAnswer = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close if already open
    } else {
      setActiveIndex(index); // Open selected question
    }
  };
  const [product, setProduct] = useState([]);
  const [service, setService] = useState([]);

  const handleProductChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setProduct([...product, value]);
    }

    // Case 2  : The user unchecks the box
    else {
      setProduct(product.filter((e) => e !== value));
    }
  };
  const applyChanges = () => {};

  const handleServiceChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setService([...service, value]);
    }

    // Case 2  : The user unchecks the box
    else {
      setService(service.filter((e) => e !== value));
    }
  };
  const updateContactFields = (e) => {
    const fieldName = e.target.name;
    setContact((existingValues) => ({
      // Retain the existing values
      ...existingValues,
      // update the current field
      [fieldName]: e.target.value,
    }));
  };
  const onEquirySubmit = () => {
    if (contact.name && contact.email && contact.message) {
      api
        .post("/addEnquiry", {
          name: contact.name,
          email: contact.email,
          phone: contact.phone,
          message: contact.message,
          products: product.join(","),
          services: service.join(","),
        })
        .then((res) => {
          console.log(res);
          // alert('Record created successfully', 'success');
        })
        .catch(() => {
          alert("Unable to edit record.", "error");
        });
      console.log(contact);
    } else {
      alert("Please fill all required fields.", "error");
    }
  };

  //Email
  const sendMail = () => {
    if (contact.name && contact.email && contact.message) {
      const dynamic_template_data = {
        name: contact.name,
        email: contact.email,
        message: contact.message,
        phone: contact.phone,
      };
      api.post("/sendenquiryemail", { dynamic_template_data }).then(() => {
        alert(
          "Thanks for contacting us. We will respond to your enquiry as soon as possible"
        );
      });
      window.location.reload();
    } else {
      applyChanges();
    }
  };

  React.useEffect(() => {
    AOS.init();
    getBlogs();
    window.scrollTo(0, 0);
    setTimeout(() => {}, 1000);
  }, []);

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings3 = {
    dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const getBlogs = () => {
    api.get("/getBlogImage").then((res) => {
      setBlogs(res.data.data);
    });
    api.post("/getContent", { recordType: "Testimonialpart" }).then((res) => {
      setTestimonials(res.data.data);
    });
    api.post("/getContent", { recordType: "Home Products" }).then((res) => {
      setProducts(res.data.data);
    });
    api.post("/getContent", { recordType: "Our Services" }).then((res) => {
      setServices(res.data.data);
    });
    api.post("/getContent", { recordType: "Why Choose Us" }).then((res) => {
      setWhychooseus(res.data.data);
      // console.log(res.data.data)
    });
  };
  const getServiceDetail = (title) => {
    if (title === "Web/ E Commerce Development") {
      navigate("services/website-development-software");
    } else {
      var formated = title.toLowerCase().split(" ").join("-");

      navigate("/services/" + formated);
    }
  };
  const getFormatedText = (title) => {
    var formatedd = title.toLowerCase();
    return formatedd.split(" ").join("-");
  };

  return (
    <>
      <section className="hero-area hero-area-lg position-relative">
        <Helmet>
          <meta charSet="utf-8" />
          <link
            rel="icon"
            type="image/x-icon"
            href="/public/assets/images/logo/favicon-32x32.png"
          />
          <title>Zammy Zaif</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          ></meta>
        </Helmet>
        <div class="container-full-width">
          <div
            class="swiper-container main-slider"
            data-effect="fade"
            data-autoplay="4000"
          >
            <div class="swiper-wrapper">
              <div class="swiper-slide bg-primary-color main-slider-bg-dark thumb-left">
                <div class="container table">
                  <div class="row table-cell">
                    <div class="col-lg-6 table-cell">
                      <div class="slider-content">
                        <span class="slide-span mb-10">
                          Search Engine Optimization
                        </span>
                        <h1
                          class="slider-content-title mb-10"
                          data-swiper-parallax="-100"
                        >
                          Want to <span class="c-dark">Rank</span> your Website
                          SERP's <span class="c-dark">#1</span>
                        </h1>
                        <h6
                          class="slider-content-text"
                          data-swiper-parallax="-200"
                        >
                          Since 2008, Zammy Zaif has been one of leading Google
                          SEO Expert. Our Primary objective is achieveing 1st
                          Rank Result in Google.
                        </h6>

                        <div
                          class="main-slider-btn-wrap"
                          data-swiper-parallax="-300"
                        >
                          <a
                            href="contact-zammy-zaif.php"
                            class="btn btn-medium btn--dark btn-hover-shadow"
                          >
                            <span class="text">Contact Us</span>
                            <span class="semicircle"></span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 table-cell">
                      <div
                        class="slider-thumb"
                        data-swiper-parallax="-300"
                        data-swiper-parallax-duration="500"
                      >
                        <img
                          loading="lazy"
                          src={zammy}
                          alt="Digital Influencer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide bg-secondary-color main-slider-bg-dark">
                <div class="container table">
                  <div class="row table-cell">
                    <div class="col-lg-6 table-cell">
                      <div class="slider-content">
                        <span class="slide-span mb-10">
                          Social Media Optimization
                        </span>

                        <h1
                          class="slider-content-title mb-10"
                          data-swiper-parallax="-100"
                        >
                          Optimize your Rank in{" "}
                          <span class="c-dark">Google SERP</span>
                        </h1>
                        <h6
                          class="slider-content-text"
                          data-swiper-parallax="-200"
                        >
                          Social Media Optimization, an off-page SEO strategy,
                          can enhance both your website's traffic and domain
                          authority, subsequently improving your Google ranking.
                        </h6>

                        <div
                          class="main-slider-btn-wrap"
                          data-swiper-parallax="-300"
                        >
                          <a
                            href="contact-zammy-zaif.php"
                            class="btn btn-medium btn--dark btn-hover-shadow"
                          >
                            <span class="text">Contact Us</span>
                            <span class="semicircle"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 table-cell">
                      <div
                        class="slider-thumb"
                        data-swiper-parallax="-300"
                        data-swiper-parallax-duration="500"
                      >
                        <img
                          loading="lazy"
                          src={slider3}
                          alt="slider"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="swiper-slide bg-green-color main-slider-bg-dark">
                <div class="container table">
                  <div class="row table-cell">
                    <div class="col-lg-6 table-cell">
                      <div class="slider-content">
                        <span class="slide-span mb-10">
                          Skilled Content Writer
                        </span>
                        <h1
                          class="slider-content-title mb-10"
                          data-swiper-parallax="-100"
                        >
                          Hire a Professional{" "}
                          <span class="c-dark">Content Writer</span>
                        </h1>
                        <h6
                          class="slider-content-text"
                          data-swiper-parallax="-200"
                        >
                          We provide a crisp, convincing and communicative text
                          of the sales that matches your visitors to your site
                          and increases your conversion rates.
                        </h6>

                        <div
                          class="main-slider-btn-wrap"
                          data-swiper-parallax="-300"
                        >
                          <a
                            href="contact-zammy-zaif.php"
                            class="btn btn-medium btn--dark btn-hover-shadow"
                          >
                            <span class="text">Contact Us</span>
                            <span class="semicircle"></span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 table-cell">
                      <div
                        class="slider-thumb"
                        data-swiper-parallax="-300"
                        data-swiper-parallax-duration="500"
                      >
                        <img
                          loading="lazy"
                          src={slider2}
                          alt="slider"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <svg class="btn-next btn-next-black">
              <use href="#arrow-right"></use>
            </svg>

            <svg class="btn-prev btn-prev-black">
              <use href="#arrow-left"></use>
            </svg>

            <div class="slider-slides">
              <a href="#" class="slides-item bg-primary-color">
                <div class="content">
                  <div class="text-wrap">
                    <h4 class="slides-title">Search Engine Result Page</h4>
                    <div class="slides-sub-title">
                      Want to Rank #1 on Google
                    </div>
                  </div>
                  <div class="slides-number">01</div>
                </div>
              </a>

              <a href="#" class="slides-item bg-secondary-color">
                <div class="content">
                  <div class="text-wrap">
                    <h4 class="slides-title">Optimize your Business</h4>
                    <div class="slides-sub-title">
                      Content Doesn’t Win. Optimized Content Wins
                    </div>
                  </div>
                  <div class="slides-number">02</div>
                </div>
              </a>

              <a href="#" class="slides-item bg-green-color">
                <div class="content">
                  <div class="text-wrap">
                    <h4 class="slides-title">With Captivating Content</h4>
                    <div class="slides-sub-title">
                      Reach your Targeted Audiences
                    </div>
                  </div>
                  <div class="slides-number">03</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container info-boxes pt100 pb100">
          <div class="row flex-reverse">
            <div class="col-lg-4 md-mb-30 text-center">
              <img src={seoleadcon} alt="SEO leads" title="" />
            </div>

            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-6 col-xs-12">
                  <div class="info-box--standard" data-mh="info-boxes">
                    <div class="info-box-image">
                      <img
                        loading="lazy"
                        src={seoaudit}
                        alt="SEO Audits"
                        title=""
                      />
                    </div>
                    <div class="info-box-content">
                      <h5 class="info-box-title">SEO Audits</h5>
                      <p class="text mb-0">
                        We provide an analyzed SEO audit of website's overall
                        performance, adhering to Google protocols for technical
                        on-page and off-page SEO.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-xs-12">
                  <div class="info-box--standard" data-mh="info-boxes">
                    <div class="info-box-image">
                      <img
                        loading="lazy"
                        src={localseo}
                        alt="Local SEO"
                        title=""
                      />
                    </div>
                    <div class="info-box-content">
                      <h5 class="info-box-title">Local SEO</h5>
                      <p class="text mb-0">
                        We offer local SEO services, optimize your website for
                        relevant audiences, increase your visibility, sales in
                        local search results.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-xs-12">
                  <div class="info-box--standard" data-mh="info-boxes">
                    <div class="info-box-image">
                      <img
                        loading="lazy"
                        src={increasingkeyword}
                        alt="Increasing Keywords Rank"
                        title=""
                      />
                    </div>
                    <div class="info-box-content">
                      <h5 class="info-box-title">Increasing Keywords Rank</h5>
                      <p class="text mb-0">
                        By our analyzed keyword ranking services, increase
                        website's traffic, generate more leads, sales, and
                        enhance potential credibility.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-xs-12">
                  <div class="info-box--standard" data-mh="info-boxes">
                    <div class="info-box-image">
                      <img loading="lazy" src={contentwriter} title="" />
                    </div>
                    <div class="info-box-content">
                      <h5 class="info-box-title">Content Writer</h5>
                      <p class="text mb-0">
                        We write an informative SEO-friendly content, targeting
                        your audience and optimized for search engines,
                        improving website's ranking.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-fluid">
          <div class="row">
            <div class="seo-score scrollme" style={{ width: "100%" }}>
              <div class="container">
                <div class="row">
                  <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-xs-12 col-sm-12">
                    <div class="seo-score-content align-center">
                      <div class="heading align-center">
                        <h3 class="h3 heading-title">
                          Looking for More Traffic & Leads?
                        </h3>
                        <p class="heading-text">
                          We have helped 38 top brands in the world and 250+
                          local brands for the last 14 years.
                        </p>
                      </div>

                      <div class="seo-score-form">
                        <form
                          class="input-inline"
                          method="post"
                          action="https://bamstechnologies.org/mailer/it2-leads.php"
                        >
                          <div class="row">
                            <div class="col-lg-5 no-padding col-md-12 col-xs-12 col-sm-12">
                              <input
                                name="web"
                                class="input-dark site"
                                placeholder="Type in your Website URL*"
                                required
                              />
                            </div>
                            <div class="col-lg-3 no-padding col-md-12 col-xs-12 col-sm-12">
                              <input
                                name="phone"
                                class="input-dark phone"
                                placeholder="Your Phone*"
                                type="number"
                                required
                              />
                            </div>
                            <div class="col-lg-4 no-padding col-md-12 col-xs-12 col-sm-12">
                              <input
                                name="email"
                                class="input-dark e-mail"
                                placeholder="Your Email*"
                                type="email"
                                required
                              />
                            </div>

                            <div class="col-lg-12 no-padding col-md-12 col-xs-12 col-sm-12">
                              <input
                                style={{
                                  borderRadius: "50px",
                                  paddingTop: "10px",
                                }}
                                name="text"
                                class="input-dark site"
                                placeholder="Subject"
                                required
                              />
                            </div>
                          </div>
                          <input type="hidden" name="page_url" value="" />
                          <div
                            class=""
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              data-sitekey="6LcsApkhAAAAAMYgkxg_Du9jRbCXlHuJbCanu8FU"
                              required
                              class="g-recaptcha"
                            ></div>
                          </div>

                          <button class="btn btn-medium btn--green btn-hover-shadow">
                            <span class="text">Enquiry Now!</span>
                            <span class="semicircle"></span>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="images">
                {/* <img loading="lazy" src={seoscore1} alt="image" style={{opacity:1, top:'-10px', paddingTop:'300px'}} /> */}
                <img
                  loading="lazy"
                  src={seoscore2}
                  alt="image"
                  style={{ opacity: 1, top: "-10px", paddingTop: "540px" }}
                />
                <img
                  loading="lazy"
                  src={seoscore3}
                  alt="image"
                  style={{ opacity: 1, top: "-10px", paddingTop: "350px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container">
          <div class="medium-padding120">
            <div class="offers">
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 md-mb-30 text-m-center">
                  <div class="heading">
                    <span>Zammy Zaif</span>
                    <h2 class="h2 heading-title mb-0">
                      Google Ranking Services
                    </h2>
                  </div>
                  <p class="text-l-justify mb-10">
                    For over a decade, Zammy Zaif has been consistently
                    providing first-ranking SEO services. Zammy's content is
                    valuable and informative, directly related to Google's
                    search engine ranking algorithms. The content is structured
                    and optimized to be easily indexed and crawled by search
                    engines. Through his expertise in Google ranking services,
                    Zammy has helped numerous websites to exhibits a high level
                    of user engagement while enhancing the website’s reputation
                    and authority in their respective fields with social media
                    shares, backlinks and reviews. As a result of his track
                    record, Google has recognized Zammy Zaif as the World's
                    Finest Google Ranking Expert in the featured snippet.
                  </p>
                  <a
                    href="google-ranking-expert.php"
                    class="btn btn-medium btn--primary btn-hover-shadow"
                  >
                    <span class="text">Get Started</span>
                    <span class="semicircle"></span>
                  </a>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-m-center">
                  <img
                    src={googlerankingservice}
                    alt="Google Ranking Services"
                    title=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-fluid">
          <div class="row">
            <div class="our-video js-equal-child">
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding">
                <div class="video theme-module">
                  <div class="video-thumb">
                    <div class="overlay"></div>
                    <a
                      href="https://www.youtube.com/watch?v=F1dRUHlZoLg"
                      class="video-control js-popup-iframe"
                    >
                      <img loading="lazy" src={videosvg} alt="go" />
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-lg-offset-1 col-md-4 col-md-offset-1 col-sm-12 col-xs-12 no-padding text-m-center">
                <div class="content theme-module centered-on-mobile medium-padding100">
                  <div class="heading">
                    <span>Who we are</span>
                    <h3 class="h3 heading-title mb-0">Bams Technologies inc</h3>
                    <div class="heading-line">
                      <span class="short-line"></span>
                      <span class="long-line"></span>
                    </div>
                  </div>
                  <p class="text-l-justify mb-20">
                    Since 2008, Bams Technologies has been providing exceptional
                    SEO services to companies worldwide. As a premier Google SEO
                    service provider, we offer comprehensive SEO solutions to
                    all types of businesses locally, regionally and globally. We
                    specialize in Google SEO and customize SEO packages
                    according to specific needs of our clients worldwide.
                  </p>
                  <a
                    href="about-zammy-zaif.php"
                    class="btn btn-medium btn--secondary"
                  >
                    <span class="text">About Us</span>
                    <span class="semicircle"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-fluid">
          <div class="row">
            <div
              class="background-mountains medium-padding120 scrollme"
              style={{ width: "100%" }}
            >
              <div class="images">
                <img
                  loading="lazy"
                  style={{ opacity: 1, bottom: "0px", paddingBottom: "10%" }}
                  src={mountain1}
                  alt="mountain"
                />
                <img
                  loading="lazy"
                  style={{ opacity: 1, bottom: "0px", paddingBottom: "10%" }}
                  src={mountain2}
                  alt="mountain"
                />
              </div>

              <div class="container">
                <div
                  id="swiper-unique-id-1"
                  class="swiper-container pagination-bottom swiper-swiper-unique-id-1 initialized swiper-container-horizontal"
                >
                  <div
                    class="swiper-wrapper"
                    style={{
                      transform: "translate3d(-2280px 0px 0px)",
                      transitionDuration: "0ms",
                    }}
                  >
                    <div
                      class="testimonial-slider-standard-item swiper-slide swiper-slide-duplicate swiper-slide-prev"
                      data-swiper-slide-index="5"
                      style={{ width: "1140px" }}
                    >
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-m-center md-mb-30">
                          <div class="offers-thumb">
                            <img
                              loading="lazy"
                              src={bestseo}
                              alt="Best Google SEO Expert"
                            />
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4 mb-lg-0 text-m-center">
                          <span class="subhead">Certified</span>
                          <h2>SEO Ranking Expert</h2>
                          <p class="text-l-justify mb-20">
                            Zammy Zaif is an certified Google ranking SEO expert
                            with over a decade of experience in the digital
                            marketing industry. He specializes in Google search
                            engine optimization and has worked with various
                            industries globally, giving him unparalleled
                            insights into the nebulous and profound aspects of
                            SEO. Zammy's focus on providing measurable results
                            has helped him gain a reputation as a leader in the
                            Google SEO industry. He aims for perfection in every
                            task to meet his clients expectations, setting new
                            benchmarks with every completed project.
                          </p>

                          <a
                            href="about-zammy-zaif.php"
                            class="btn btn-medium btn--primary btn-hover-shadow"
                          >
                            <span class="text">Get Started</span>
                            <span class="semicircle"></span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="testimonial-slider-standard-item swiper-slide">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 md-mb-30 text-m-center">
                          <img
                            loading="lazy"
                            src={contentwriter}
                            alt="Content Writer"
                            title=""
                          />
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4 mb-lg-0 text-m-center">
                          <span class="subhead">
                            SEO-Friendly Writing Services
                          </span>
                          <h2>Optimized Content Writing</h2>
                          <p class="text-l-justify">
                            With over a decade of experience, Zammy has mastered
                            the art of words by working with a wide range of
                            clients worldwide and specializing in offering
                            SEO-friendly content writing services. Using his
                            advanced professional writing skills he offers
                            custom writing solutions for SOPs, articles, blogs
                            and website content.
                          </p>
                          <p class="text-l-justify">
                            With thorough research and strategic use of
                            appropriate keywords targeting the relevant
                            audience, he creates unique and informative
                            optimized content for the Google search engine to
                            enhance your website’s visibility and rankings.
                          </p>
                          <a
                            href="about-zammy-zaif.php"
                            class="btn btn-medium btn--dark"
                          >
                            <span class="text">Get Started</span>
                            <span class="semicircle"></span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="testimonial-slider-standard-item swiper-slide">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-m-center md-mb-30">
                          <img
                            loading="lazy"
                            src={digitalmarketing}
                            alt="Digital Marketing"
                            title=""
                          />
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4 mb-lg-0 text-m-center">
                          <span class="subhead">Website Ranking Service</span>
                          <h2>Rank Your Website #1</h2>
                          <p class="text-l-justify">
                            Bams Technologies specializes in providing Google
                            1st rank SEO services for websites within 90-180
                            days, increasing organic traffic by 50% and CTR by
                            20%. With a skilled team that strictly adheres to
                            all Google protocols to ensure website security, the
                            best user experience, and unique content to enhance
                            brand awareness, while achieving rankings on top
                            pages. By effectively targeting the right audience
                            using the AIDA strategy, conducting comprehensive
                            keyword research and implementing strategic link
                            building.
                          </p>
                          <a
                            href="about-zammy-zaif.php"
                            class="btn btn-medium btn--dark"
                          >
                            <span class="text">Get Started</span>
                            <span class="semicircle"></span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="testimonial-slider-standard-item swiper-slide">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 md-mb-30 text-m-center">
                          <img
                            loading="lazy"
                            src={seoaudit}
                            alt="Website Audit"
                            title=""
                          />
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4 mb-lg-0 text-m-center">
                          <span class="subhead">
                            Analyse your Page performance
                          </span>
                          <h2>Website Auditing</h2>
                          <p class="text-l-justify">
                            We are the followers of Google UI UX Standards with
                            a creative team of website designers and developers.
                            Our Team provide professional website audit services
                            that ensure 100% client satisfaction, with clear
                            understanding of customer requirements and business
                            owner's mindset.
                          </p>
                          <p class="text-l-justify">
                            With our experience, we have conducted audits
                            worldwide for various types of websites from
                            e-commerce platforms to service-oriented websites. A
                            website audit involves analyzing page performance
                            before making any significant search engine
                            optimization (SEO) changes or website redesigns to
                            achieve more traffic and sales.
                          </p>
                          <a
                            href="about-zammy-zaif.php"
                            class="btn btn-medium btn--dark"
                          >
                            <span class="text">Get Started</span>
                            <span class="semicircle"></span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="testimonial-slider-standard-item swiper-slide">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-xs-12 text-m-center md-mb-30">
                          <img
                            loading="lazy"
                            src={whoiszammy}
                            alt="Zammy Zaif"
                            title="Zammy Zaif is a well-known SEO expert in India who offers the best organic SEO Services for your company."
                          />
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4 mb-lg-0 text-m-center">
                          <span class="subhead">SEO Ranking Services</span>
                          <h2>Google SEO Expert</h2>
                          <p class="text-l-justify">
                            As an authorized Google SEO partner, we utilize
                            effective SEO strategies that strictly adhere to all
                            Google algorithms and Google Webmaster standards.
                            Bams Technologies offers the best SEO expert
                            services for the Google search engine.
                          </p>
                          <p class="text-l-justify">
                            With our expertise, we have positively contributed
                            website rankings on the Google search engine for the
                            clients from all over the globe. Working with a
                            skilled Google SEO expert, you can elevate your
                            website’s rankings to higher levels, resulting in
                            increased visitors and potential sales. Through our
                            Google SEO services, grow your organization
                            digitally above all the other competitors in the
                            field of SEO.
                          </p>
                          <a
                            href="best-seo-expert-india.php"
                            class="btn btn-medium btn--dark"
                          >
                            <span class="text">Get Started</span>
                            <span class="semicircle"></span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="testimonial-slider-standard-item swiper-slide">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-m-center md-mb-30">
                          <img
                            loading="lazy"
                            src={socialmarketing}
                            alt="Digital Marketing"
                            title=""
                          />
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4 mb-lg-0 text-m-center">
                          <span class="subhead">GMB Ranking Service</span>
                          <h2>Get No.1 Rank in GMB</h2>
                          <p class="text-l-justify">
                            To achieve a first rank position on Google Maps,
                            expert knowledge of GMB SEO strategies is required.
                            Our team strictly follows Google guidelines,
                            optimizes for the right audience, increases organic
                            traffic, generates high-quality backlinks, and
                            monitors spam networks to avoid penalties. Regular
                            updates to your Google Business profile also engage
                            customers and drive revenue. Our clients usually
                            rank first on Google Maps within 45 days. Trust our
                            team of GMB experts to help you achieve your goals
                            for Google Business Profile Optimization.
                          </p>
                          <a
                            href="about-zammy-zaif.php"
                            class="btn btn-medium btn--dark"
                          >
                            <span class="text">Get Started</span>
                            <span class="semicircle"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <svg class="btn-next">
                    <use href="#arrow-right"></use>
                  </svg>

                  <svg class="btn-prev">
                    <use href="#arrow-left"></use>
                  </svg>
                  <div class="swiper-pagination pagination-swiper-unique-id-1 swiper-pagination-clickable swiper-pagination-bullets">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-fluid">
          <div class="row bg-green-color">
            <div class="container">
              <div class="row">
                <div class="counters">
                  <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="counter-item" style={{ marginRight: "150px" }}>
                      <div class="counter-numbers counter">
                        <span
                          data-speed="2000"
                          data-refresh-interval="3"
                          data-to="170"
                          data-from="2"
                        >
                          170
                        </span>
                        <div class="units">+</div>
                      </div>
                      <span class="counter-title">Customers</span>
                      <div class="counter-line">
                        <span class="short-line"></span>
                        <span class="long-line"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="counter-item">
                      <div class="counter-numbers counter">
                        <span
                          data-speed="2000"
                          data-refresh-interval="3"
                          data-to="12"
                          data-from="0"
                        >
                          12
                        </span>
                        <div class="units">+</div>
                      </div>
                      <span class="counter-title">Years of Service</span>
                      <div class="counter-line">
                        <span class="short-line"></span>
                        <span class="long-line"></span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="counter-item">
                      <div class="counter-numbers counter">
                        <span
                          data-speed="2000"
                          data-refresh-interval="3"
                          data-to="2"
                          data-from="1"
                        >
                          2
                        </span>
                        <div class="units">K+</div>
                      </div>
                      <span class="counter-title">Results</span>
                      <div class="counter-line">
                        <span class="short-line"></span>
                        <span class="long-line"></span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="counter-item">
                      <div class="counter-numbers counter">
                        <span
                          data-speed="2000"
                          data-refresh-interval="3"
                          data-to="99"
                          data-from="50"
                        >
                          99
                        </span>
                        <div class="units">%</div>
                      </div>
                      <span class="counter-title">Satisfied Clients</span>
                      <div class="counter-line">
                        <span class="short-line"></span>
                        <span class="long-line"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container">
          <div class="row medium-padding120">
            <div class="recent-case align-center">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
                    <div class="heading align-center">
                      <span>Zammy's Achievements</span>
                      <h2 class="h2 heading-title mb-0">
                        Our Ranking Achievements
                      </h2>
                      <div class="heading-line">
                        <span class="short-line"></span>
                        <span class="long-line"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="case-item-wrap">
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                      <div class="case-item">
                        <div class="case-item__thumb">
                          <img
                            loading="lazy"
                            src={nabtakar}
                            alt="Google No.1 Result"
                            title=""
                          />
                        </div>
                        <h6 class="case-item__title">
                          <a href="https://nabtaker.com/" target="_blank">
                            Nabtaker Sign
                          </a>
                        </h6>
                        <p style={{fontSize:'16px'}}>
                          Keywords: dx5 bahrain, bodor laser bahrain, bodor cnc
                          bahrain, dx5 ink bahrain.
                        </p>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                      <div class="case-item">
                        <div class="case-item__thumb">
                          <img
                            loading="lazy"
                            src={hydralic}
                            alt="Google No.1 Result"
                            title=""
                          />
                        </div>
                        <h6 class="case-item__title">
                          <a href="http://hydraulicsoman.com/" target="_blank">
                            Hydraulic Oman
                          </a>
                        </h6>
                        <p style={{fontSize:'16px'}}>
                          Keywords: Hydraulic oman, Hydraulic spares in oman,
                          Hydraulic systems in oman
                        </p>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                      <div class="case-item">
                        <div class="case-item__thumb">
                          <img
                            loading="lazy"
                            src={venture}
                            alt="Google No.1 Result"
                            title=""
                          />
                        </div>
                        <h6 class="case-item__title">
                          <a href="https://vpqatar.com/" target="_blank">
                            Venture Partner - Qatar
                          </a>
                        </h6>
                        <p style={{fontSize:'16px'}}>
                          Keywords:Venture Partner Qatar,corporate sponsorship
                          ,Local Sponsorship and Pro Service Qatar.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-fluid">
          <div class="row">
            <div class="testimonial-slider scrollme" style={{width:'100%',padding:'50px'}}>
              <div class="container">
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="heading">
                      <h4 class="h4 heading-title mb-0">
                        Happy Clients About Us
                      </h4>
                      <div class="heading-line">
                        <span class="short-line bg-yellow-color"></span>
                        <span class="long-line bg-yellow-color"></span>
                      </div>
                      <p class="heading-text c-white" >
                        Read what our client say about our SEO services, our
                        client support & other things.
                      </p>
                    </div>
                  </div>

                  <div class="col-lg-7 col-lg-offset-1 col-md-8 col-sm-12 col-xs-12">
                    <div class="testimonial-item">
                      <div
                        class="swiper-container testimonial__thumb overflow-visible"
                        data-effect="fade"
                        data-loop="false"
                      >
                        <div class="swiper-wrapper">
                          <div class="testimonial-slider-item swiper-slide">
                            <div class="testimonial-content">
                              <p class="text" data-swiper-parallax="-200" style={{fontSize:'16px'}}>
                                Thank you for providing SEO services. My company
                                was ranked first in Google Search and in
                                Bahrain. In Bahrain, you may find printers,
                                lasers, CNC machines, cutting plotters,
                                machineries, and spare parts. Your SEO strategy
                                worked for me; I'm pleased with the high-quality
                                backlinks that were established, and I used all
                                professional marketing techniques to get my
                                website to the top of the Google search results.
                                Expertise designed to establish a successful SEO
                                strategy for my business. For my company, you've
                                created a result-oriented SEO approach.
                              </p>
                              <a
                                href="https://nabtaker.com/"
                                target="_blank"
                                class="author"
                                data-swiper-parallax="-150"
                              >
                                Ahmed Abdulaal
                              </a>
                              <a
                                href="https://nabtaker.com/"
                                target="_blank"
                                class="company"
                                data-swiper-parallax="-150"
                              >
                                Founder (NABTAKER SIGN)
                              </a>
                            </div>
                            <div class="avatar" data-swiper-parallax="-50">
                              <img loading="lazy" src={avatar} alt="avatar" />
                            </div>
                          </div>
                        </div>
                        <div class="swiper-pagination"></div>

                        <div class="quote">
                          <i class="seoicon-quotes"></i>
                        </div>
                      </div>

                      <div class="testimonial__thumb-img">
                        <img loading="lazy" src={testimonial1} alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="testimonial-img" style={{marginTop:'-100px'}}>
                <img loading="lazy" src={testimonial2} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container-fluid">
          <div class="row medium-padding120 bg-border-color">
            <div class="container">
              <div class="heading mb40 align-center">
                <span class="faq-label">FAQ</span>
                <h3 class="h3 heading-title mb-0">
                  Frequently Asked Questions
                </h3>

                <div class="heading-line">
                  <span class="short-line"></span>
                  <span class="long-line"></span>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 md-mb-30">
                  <img src={faq} alt="faq" title="" class="faq-image" />
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <div id="accordion" class="crumina-module crumina-accordion">
                    <div class="card accordion-panel mb-20">
                      <div class="card-header panel-heading" id="headingOne">
                        <button
                          class="btn-link accordion-heading"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <span class="icon">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                          </span>
                          <span class="title">
                            Is your Phone Number not displaying?
                          </span>
                        </button>
                      </div>
                      <div
                        id="collapseOne"
                        class="panel-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          <div class="panel-info">
                            If your phone number is not displaying on your
                            Google My Business listing, there could be a few
                            reasons for this issue...
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card accordion-panel mb-20">
                      <div class="card-header panel-heading" id="headingTwo">
                        <button
                          class="btn-link accordion-heading collapsed"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="true"
                          aria-controls="collapseTwo"
                        >
                          <span class="icon">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                          </span>
                          <span class="title">How to claim your Business?</span>
                        </button>
                      </div>
                      <div
                        id="collapseTwo"
                        class="panel-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          <div class="panel-info">
                            Claiming your business on Google My Business is a
                            straightforward process. Start by visiting the
                            Google My Business website...
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card accordion-panel mb-20">
                      <div class="card-header panel-heading" id="headingThree">
                        <button
                          class="btn-link accordion-heading collapsed"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="true"
                          aria-controls="collapseThree"
                        >
                          <span class="icon">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                          </span>
                          <span class="title">
                            Do you want to change your Location?
                          </span>
                        </button>
                      </div>
                      <div
                        id="collapseThree"
                        class="panel-collapse collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          <div class="panel-info">
                            If you need to change the location of your business
                            on your Google My Business listing, you can do so by
                            following these steps...
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card accordion-panel mb-20">
                      <div class="card-header panel-heading" id="headingFour">
                        <button
                          class="btn-link accordion-heading collapsed"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        >
                          <span class="icon">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                          </span>
                          <span class="title">
                            What to do if your Business Listing is not
                            Optimized?
                          </span>
                        </button>
                      </div>
                      <div
                        id="collapseFour"
                        class="panel-collapse collapse"
                        aria-labelledby="headingFour"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          <div class="panel-info">
                            If your business listing on Google My Business is
                            not optimized, you may be missing out on valuable
                            opportunities to attract customers...
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
